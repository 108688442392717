import Vue from 'vue';
import App from './App.vue';
import vuetify from '@/plugins/vuetify';
import storeInit from './store';
import './assets/css/main.scss';
import routes from './router.js';
import VueRouter from 'vue-router';
import VueCookies from 'vue-cookies';
import VueHtmlToPaper from 'vue-html-to-paper';
import VueQRCodeComponent from 'vue-qrcode-component'
import VueScreenSize from 'vue-screen-size'
Vue.use(VueScreenSize)


Vue.component('qr-code', VueQRCodeComponent)

Vue.use(VueCookies);
Vue.$cookies.config('90d','','',true) // 90 dagars ExpireTime på cookies och secure = true

Vue.config.productionTip = false;
const store = storeInit();

Vue.use(VueRouter);
const router = new VueRouter(routes);

const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  //theme: { dark:true},
  styles: [
     //'https://dev.infoping.se/v/css/app.b18d5eeb.css'
     

    // './src/assets/css/main.css'
    //  './assets/css/main.css'
    // './css/main.css'
     './main.css',

    // '../src/assets/css/main.css'
    // '../assets/css/main.css'
    // '../css/main.css'
    // '../main.css'
    
    // '/src/assets/css/main.css'
    // '/assets/css/main.css'
    // '/css/main.css'
    // '/main.css'

    //  '@/src/assets/css/main.css'
    // '@/assets/css/main.css'
    // '@/css/main.css'
    // '@/main.css'
  ]
}

Vue.use(VueHtmlToPaper, options);

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
