<template>
  <div
    :style="$vuetify.breakpoint.smAndUp ? null : 'margin-right: 25px'"
    :class="{ desktopView: $vuetify.breakpoint.mdAndUp }"
  >
    <br />

    <br />

    <v-container class="borderContainer">
      <label>RAPPORTER</label>
      <br />
      <br />

      <!-- Kund org_key-->
      <v-select
        v-if="this.support == '1'"
        outlined
        dense
        class="overflowOverride"
        :items="orgs"
        item-text="name"
        item-value="org_key"
        label="Välj kund:"
        v-model="selectedCustomer"
      ></v-select>
      <!-- Rapport-->
      <v-select
        outlined
        dense
        item-text="name"
        item-value="id"
        return-object
        class="overflowOverride"
        :items="reports"
        label="Välj rapport:"
        v-model="selectedReport"
      ></v-select>
      <div v-if="selectedReport">
        <!-- Kontor customerid-->
        <v-select
          v-if="vIfSelectOffice()"
          outlined
          dense
          class="overflowOverride"
          :items="offices"
          item-text="name"
          item-value="customerId"
          return-object
          label="Välj kontor:"
          v-model="selectedOffice"
        ></v-select>

        <!-- Mäklare -->
        <v-select
          v-if="vIfSelectBroker()"
          outlined
          dense
          class="overflowOverride"
          :items="brokers"
          item-text="name"
          item-value="id"
          return-object
          label="Välj mäklare:"
          v-model="selectedBroker"
        ></v-select>
        <!--försättssida-->
        <v-select
          v-if="vIfCompConfig()"
          outlined
          dense
          item-text="text"
          item-value="value"
          class="overflowOverride"
          :items="reportDetails"
          label="Försättssida:"
          v-model="selectedDetails"
        ></v-select>
        <!-- svar-->
        <v-select
          v-if="vIfCompConfig()"
          outlined
          dense
          item-text="text"
          item-value="value"
          class="overflowOverride"
          :items="reportAnswers"
          label="Visa detaljerade svar:"
          v-model="selectedAnswers"
        ></v-select>
        <!-- from datum-->
        <v-menu
          v-if="vIfFromDate()"
          ref="menu"
          v-model="menu"
          :close-on-content-click="true"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              outlined
              dense
              v-model="fromDate"
              label="Fr.o.m"
              append-icon="mdi-calendar"
              @click:append="menu = true"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="fromDate"
            no-title
            scrollable
            :first-day-of-week="1"
            locale="sv-se"
          >
          </v-date-picker>
        </v-menu>
        <v-label
          v-if="
            this.selectedReport.id == 'report_viewing_statistics' &&
              this.customisedReport == true
          "
          >Välj måndagen i den vecka du vill generera rapport för
        </v-label>
        <v-label
          v-if="
            this.selectedReport.id == 'report_access_survey_comments' &&
              this.customisedReport == true
          "
          >Välj det datum du vill se kommentarer för
        </v-label>
        <!-- till datum-->
        <v-menu
          v-if="vIfToDate()"
          ref="menu"
          v-model="menu2"
          :close-on-content-click="true"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              outlined
              dense
              v-model="toDate"
              label="T.o.m."
              append-icon="mdi-calendar"
              @click:append="menu2 = true"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="toDate"
            no-title
            scrollable
            :first-day-of-week="1"
            locale="sv-se"
          >
          </v-date-picker>
        </v-menu>
        <!--   </div>-->
        <v-switch
          v-model="customisedReport"
          label="Anpassad rapport"
        ></v-switch>
        <div v-if="this.customisedReport == false">
          <v-label
            >Om du aktiverar "Anpassad rapport" kan du specificera vad du vill
            ha med i rapporten. Annars genereras den standardrapport som normalt
            skickas via mail.
          </v-label>
        </div>
      </div>
      <br />
      <v-btn block @click="getReport()"> Skapa rapport</v-btn>
    </v-container>
  </div>
</template>

<script>
import get from 'lodash/get';
import componentsMixin from '@/components/componentsMixin.js';
//import Q from '@/questions.js';
//import { mapGetters } from 'vuex';
//import rest2Api from '@/api/rest2Api.js';

export default {
  name: 'Reports',
  mixins: [componentsMixin],
  data() {
    return {
      selectedOffice: '',
      selectedReport: '',
      offices: [],
      //orgs: [],
      //  reports: [],
      customisedReport: false,
      reports: [],
      toDate: '',
      fromDate: '',
      menu: false,
      menu2: false,
      selectedCustomer: '',

      //  selectedBroker: { name: 'Alla mäklare', id: '0' },
      selectedBroker: '0',
      reportAnswers: [
        { text: 'Visa inga svar', value: '0' },
        { text: 'Visa endast svar med kommentarer', value: '1' },
        { text: 'Visa alla svar', value: '2' },
      ],
      reportDetails: [
        { text: 'Sammanställning', value: '1' },
        { text: 'Ingen sammanställning, endast svaren', value: '0' },
      ],
      selectedDetails: '',
      selectedAnswers: '',
      support: '0',

      session: get(this.$store.state.infopingstore, 'session', null),
      org_key: '',
    };
  },
  watch: {
    selectedOffice(val) {
      if (val && val != null) {
        this.$store.dispatch('infopingstore/clear_brokers');

        this.selectedBroker = '';

        this.fetchBrokers();
      }
    },
    selectedCustomer() {
      this.$store.dispatch('infopingstore/clear_brokers');
      this.org_key = this.selectedCustomer;
      this.clearSettings();
      this.customisedReport = false;
      this.selectedReport = null;
      this.findReports();
    },

    selectedReport(val) {
      if (val && val != null) {
        this.offices = [];
        //  this.clearSettings();
        this.customisedReport = false;
        this.fetchBrokerOffices(val.id);
        // välj ett kontor om det bara finns ett

        if (
          this.vIfSelectOffice() == true &&
          this.offices &&
          this.offices.length == '1'
        ) {
          this.selectedOffice = this.offices[0];
        }
      }
    },

    customisedReport(val) {
      if (val == true) {
        var from = this.vIfFromDate();
        var to = this.vIfToDate();
        if (from == true) this.fromDate = this.getDate();

        if (to == true) this.toDate = new Date().toISOString().substr(0, 10);

        var details = this.vIfCompConfig();
        if (details == true) {
          this.selectedDetails = '1';
          this.selectedAnswers = '1';
        }
        //om kontor och  bara ett kontor välj det
        if (
          this.vIfSelectOffice() == true &&
          this.offices &&
          this.offices.length == '1'
        ) {
          this.selectedOffice = this.offices[0];
        }

        if (
          this.vIfSelectBroker() == true &&
          this.brokers &&
          this.brokers.length == '1'
        ) {
          this.selectedBroker = this.brokers[0];
        }
      } else if (val == false) {
        this.clearSettings();
      }
    },

    brokers() {
      if (
        this.vIfSelectBroker() == true &&
        this.brokers &&
        this.brokers.length == '1'
      ) {
        this.selectedBroker = this.brokers[0];
      }
    },
  },
  computed: {
    //   ...mapGetters({ orgs: 'infopingstore/report_orgs',  }), //  { customers: 'infopingstore/customers' },
    orgs() {
      var orgs = this.$store.getters['infopingstore/access_orgs']('report');

      return orgs;
    },

    brokers() {
      var arr = new Array();
      var report_brokers = get(this.$store.state.infopingstore, 'brokers', []);

      if (report_brokers.length > 0) {
        report_brokers.forEach((element) => {
          if (
            element.customers[0].items &&
            element.customers[0].items.length > 0
          ) {
            element.customers[0].items.forEach((broker) => {
              var line = {
                name: broker.name,
                id: broker.value,
              };
              if (!arr.includes(line)) arr.push(line);
            });
          }
        });
        // sortera array med objekt
        arr.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      }

      arr.unshift({ name: 'Alla mäklare', id: '0' });

      //går inte att kolla length om den inte finns så vi tar bort "alla mäklare" igen om de bara är en mäklare
      if (arr && arr.length == 2 && arr[0].id == '0') arr.shift();

      return arr;
    },
  },

  beforeMount() {
    this.is_support();
  },
  mounted() {
    if (this.support == '0') this.findReports();
  },
  created() {},

  methods: {
    findReports() {
      var access = get(this.$store.state.infopingstore, 'access', []);

      //hitta alla rapporter i access
      var allReports = access.filter(
        (r) =>
          r.name.includes('report') &&
          // filtrera fram rapporter för vald org_key
          r.orgs.some((o) => o.org_key == this.org_key)
      );

      // översätt namnet men behålla originalnamnet som id
      var myReports = allReports.map((r) => {
        return {
          id: r.name,
          name: this.translateReportName(r.name),
        };
      });

      myReports.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );
      this.reports = myReports;

      //om endast en rapport välj den
      if (this.reports && this.reports.length == '1')
        this.selectedReport = this.reports[0];
      // myReports = myReports.sort(); funkar inte
      // return myReports;
    },

    fetchBrokerOffices(report) {
      this.offices = this.$store.getters['infopingstore/broker_offices'](
        report,
        this.org_key
      );

      if (
        this.selectedReport.id != 'report_viewing_statistics' &&
        this.offices &&
        this.offices.length > 1
      ) {
        this.offices.unshift({ name: 'Alla kontor', customerId: '0' });
        this.selectedOffice = this.offices[0];
      }
    },

    fetchBrokers() {
      var obj = {};
      if (this.org_key == null || this.org_key == '')
        this.org_key = this.selectedCustomer;

      if (this.selectedOffice.customerId == '0') {
        this.$store.dispatch('infopingstore/clear_brokers');
      } else {
        obj.customerId = this.selectedOffice.customerId;
        obj.org_key = this.org_key;
        obj.name = this.selectedReport.id;
        obj.session = this.session;

        if (this.session != null) {
          this.$store.dispatch('infopingstore/fetch_brokers', obj);
        }
      }
    },

    getReport() {
      if (!this.selectedReport) {
        this.alertDialogNoCancel(
          'Ingen rapport',
          'Du måste välja vilken rapport du vill ha',

          () => console.log('user ok')
        );
      } else if (
        this.selectedReport.id == 'report_viewing_statistics' &&
        this.selectedOffice == '0'
      ) {
        this.alertDialogNoCancel(
          'Välj kontor',
          'Du måste välja vilket kontor rapporten ska genereras för',

          () => console.log('user ok')
        );
      } else if (
        this.selectedReport.id == 'report_access_survey_compilation_broker' &&
        this.selectedBroker == '0'
      ) {
        this.alertDialogNoCancel(
          'Välj mäklare',
          'Du måste välja vilken mäklare rapporten ska genereras för',

          () => console.log('user ok')
        );
      } else {
        var reportObject = {};
        reportObject.session = this.session;
        reportObject.org_key = this.org_key;
        reportObject.name = this.selectedReport.id;
        if (
          this.customisedReport == false &&
          this.selectedReport.id != 'report_viewing_statistics'
        ) {
          reportObject.report_name = 'standard'; //gäller ej visningsrapporten
        }

        //widerlöv kan inte dra ut rapporter för enskilda kontor

        if (
          this.selectedOffice &&
          this.selectedOffice.customerId != '0' &&
          this.selectedBroker.id == '0' &&
          this.org_key == '2099' &&
          this.selectedReport.id != 'report_viewing_statistics'
        ) {
          this.alertDialogNoCancel(
            'Ogiltig rapport',
            'Du kan inte välja ett kontor om du inte vill ha en rapport för en specifik mäklare. Välj mäklare eller välj "Alla kontor"',

            () => console.log('user ok')
          );
        } else {
          if (this.selectedOffice && this.selectedOffice.customerId != '0')
            reportObject.customerId = this.selectedOffice.customerId;

          if (
            this.selectedBroker &&
            this.org_key == '2099' &&
            this.selectedBroker.id != '0'
          )
            reportObject.broker_name = this.selectedBroker.name;
          else if (this.selectedBroker && this.selectedBroker.id != '0')
            reportObject.brokerId = this.selectedBroker.id;

          if (this.fromDate) {
            reportObject.date_from = this.fromDate;
          }

          if (this.toDate) reportObject.date_to = this.toDate;
          if (this.customisedReport == true && this.selectedDetails)
            reportObject.cover_page = this.selectedDetails;
          if (this.selectedAnswers && this.selectedAnswers == '1')
            reportObject.only_comments = '1';
          else if (this.selectedAnswers && this.selectedAnswers == '2')
            reportObject.all_answers = '1';

          if (this.selectedReport.id == 'report_intake')
            reportObject.content_type = 'xls';

          var settings = JSON.stringify(reportObject);
          // console.log('settings', settings);

          var link = 'https://infoping.net/REST_FILE/ask/Q/Rapport/?sok=';
          const strictUriEncode = require('strict-uri-encode');
          var encodedlink = strictUriEncode(settings);
          window.open(link + encodedlink, '_blank');
        }
      }
    },

    translateReportName(report) {
      switch (report) {
        case 'report_viewing_statistics':
          return 'Visningsrapport';

        case 'report_viewing_answers':
          return 'Visningsdeltagarnas svar';

        case 'report_office': // smh
          return 'Kundenkät';
        case 'report_access_survey_comments':
          return 'Kundenkät - gårdagens kommentarer';
        case 'report_access_survey_compilation': //widerlöv
          return 'Kundenkät - sammanställning';
        case 'report_access_survey_compilation_broker':
          return 'Kundenkät - mäklare';
        case 'report_intake':
          return 'Intag';
        default:
          return report;
      }
    },

    vIfFromDate() {
      if (this.customisedReport == true) return true;
      else return false;
    },
    vIfToDate() {
      if (
        this.selectedReport.id == 'report_access_survey_comments' ||
        this.selectedReport.id == 'report_viewing_statistics'
      )
        return false;
      else if (this.customisedReport == true) return true;
      else return false;
    },

    vIfSelectOffice() {
      if (this.selectedReport.id == 'report_viewing_statistics') return true;
      if (this.selectedReport.id == 'report_intake') return false;
      if (this.selectedReport.id == 'report_access_survey_compilation_broker')
        return true;

      if (
        this.customisedReport == true &&
        (this.selectedReport.id == 'report_office' ||
          this.selectedReport.id == 'report_access_survey_compilation' ||
          this.selectedReport.id == 'report_access_survey_compilation_broker')
      )
        return true;
      else return false;
    },

    vIfSelectBroker() {
      if (
        this.selectedReport &&
        this.selectedReport.id == 'report_access_survey_compilation_broker'
      )
        return true;

      if (
        this.customisedReport == true &&
        (this.selectedReport.id == 'report_office' ||
          this.selectedReport.id == 'report_access_survey_compilation' ||
          this.selectedReport.id == 'report_access_survey_compilation_broker')
      )
        return true;
      else return false;
    },

    vIfCompConfig() {
      if (
        this.customisedReport == true &&
        (this.selectedReport.id == 'report_office' ||
          this.selectedReport.id == 'report_access_survey_compilation' ||
          this.selectedReport.id == 'report_access_survey_compilation_broker')
      )
        return true;
      else return false;
    },

    getDate() {
      var d = new Date();
      d.setDate(d.getDate() - 30);
      d = d.toISOString().substr(0, 10);

      return d;
    },

    clearSettings() {
      this.selectedOffice = '';
      this.selectedBroker = '';
      this.selectedDetails = '';
      this.selectedAnswers = '';
      this.fromDate = '';
      this.toDate = '';
    },

    is_support() {
      //kolla om superagent annars sätt org_key

      if (
        get(this.$store.state.infopingstore, 'user_type', null) == 'superagent'
      ) {
        this.support = '1';
      } else {
        this.support = '0';

        this.org_key = get(
          this.$store.state.infopingstore,
          'org_branding.org_key',
          null
        );
      }
    },

    alertDialogNoCancel(headerText, bodyText, okFunc) {
      this.$store.dispatch('infopingstore/showAlert', {
        show: true,
        headerText: headerText,
        bodyText: bodyText,
        showCancelButton: false,
        confirmAction: () => {
          okFunc();
        },
      });
    },
  },
};
</script>
<style scoped>
/* anpassa höjden så att hela texten syns i v-select funkar inte globalt */
.overflowOverride ::v-deep .v-select__selection {
  overflow: visible !important;
}
</style>
