<template>
  <v-app>
    <div class="loginBackground">
      <span id="center">
        <Loader />
      </span>
    </div>
  </v-app>
</template>

<script>
import Loader from '../components/Loader.vue';
export default {
  name: 'login-page',
  bodyClass: 'login-page',

  data() {
    return {};
  },

  beforeMount() {},

  mounted() {},
  watch: {},
  methods: {},
  components: {
    Loader,
  },
};
</script>

<style>
body,
html {
  height: 100%;
  width: 100%;
}

.upperCaseInput input {
  text-transform: uppercase;
}

.loginBackground {
  background-image: url('~@/assets/background_1920.jpg');

  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

#center {
  width: 100%;
  height: 100%;
  margin-top: 20%;
  align-items: center;
  display: flex;
  justify-content: center;
}
</style>
