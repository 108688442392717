<template>
  <v-app :class="customCSS">
    <!--:style="{ 'font-family': fnt }"-->
    <div>
      <Alert />

      <Header v-if="not_StartViewing() && is_item_loaded == true" />
      <router-view v-if="is_item_loaded"></router-view>
      <Login
        style="width: 100vw"
        v-else-if="showLogin"
        @loginSucceeded="loggedIn"
      />
      <Default v-else />
    </div>
  </v-app>
</template>

<script>
import Default from './pages/Default.vue';
import Login from './pages/Login.vue';

import Header from './components/Header.vue';
import Alert from '@/components/Dialogs/alert.vue';

import Q from './questions.js';
import { COOKIE_NAME_SESSION } from '@/constants.js';
import get from 'lodash/get';
import componentsMixin from './components/componentsMixin.js';

import rest2Api from '@/api/rest2Api.js';
export default {
  name: 'App',
  mixins: [componentsMixin],
  data() {
    return {
      urlHasSeesion: false,
      showLogin: false,
      showUserNameDialog: false,
      inputText: '',
      lang: 'sv',
      set_cookie: true,
      is_item_loaded: false,

      //  fnt: 'semplicita_pro',
    };
  },
  components: {
    // Main,
    Login,
    Header,
    Alert,
    Default,
    //StartViewing,
  },
  watch: {},
  computed: {
    customCSS() {
      // -----------------------------------------------------------
      // CHECK ORG for special css
      // -----------------------------------------------------------
      //går inte att sätta theme här
      this.setTheme();

      if (this.org_key == '2282') return 'erik-olsson';
      else if (this.org_key == '2417') return 'smh';
      else if (this.org_key == '2283') return 'notar';
      else if (this.org_key == '2785') return 'era';
      else if (this.org_key == '2697') return 'husmanhagberg';
      else if (this.org_key == '2673') return 'vitec-dark';
      else if (this.org_key == '1330') return 'demomaklaren';
      else return 'vitec';
      //OBS! Om någon ska ha dark måste man även sätta vuetify theme dark görs längre ner
    },

    org_key() {
      return get(this.$store.state.infopingstore, 'org_branding.org_key', null);
    },
  },
  created() {},

  destroyed() {
    this.viewing_start_end('xxx', 'yyy', 'end');
    var item = get(
      this.$store.state.infopingstore,
      'selected_viewing.item',
      null
    );
    if (item != null) {
      this.viewing_start_end(item.batch_key, item.batch_name, 'end');
    }
  },
  async beforeMount() {
    //dirigera om användaren till https om de kommer via http  (endast prod)
    var loc = window.location + '';
    if (loc.indexOf('http://') == 0 && process.env.NODE_ENV === 'production') {
      window.location.href = loc.replace('http://', 'https://');
      return;
    }

    // STORE GUID
    //console.log('App','beforeMount',1,this.is_item_loaded)
    var guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(
      c
    ) {
      const r = crypto.getRandomValues(new Uint8Array(1))[0] % 16 | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
    await this.fetch_source_id(guid);
    //console.log('App','beforeMount',2,this.is_item_loaded)
    await this.fetch_source_name('Visningsassistenten');

    //console.log('App','beforeMount',3,this.is_item_loaded)

    this.lang = (navigator.language || navigator.userLanguage).substr(0, 2);

    // this.$store.dispatch('infopingstore/saveBrowserLang', this.lang);

    //console.log('App','beforeMount','3b',this.lang)
    this.lang = await this.lang_chk(this.lang);
    //await this.fetchLang();
    //console.log('App','beforeMount','3c',this.lang)
    await this.fetchLang();
    //console.log('App','beforeMount',4,this.is_item_loaded)

    // hur sessionen hanteras
    //1. kolla session i url, den gör override på allt annat. Verifiera och spara den om giltig, annars login
    //2. kolla session i cookie, verifiera och använd den.
    //3. ingen session hittats => login

    // 1. hämta s parametern ur URL (sessionen) och kolla om det är samma som i storen annars skicka till login
    const urlparams = new URLSearchParams(window.location.search);
    var session = urlparams.get('s');

    // OM MNA KOMMER FRÅN SUPER AGENT
    // var set_cookie = true;

    var cookies = urlparams.get('cookies');
    var user = urlparams.get('user');
    if (user) this.$store.dispatch('infopingstore/fetch_header_name', user);

    if (user && user != null && cookies == 'false') {
      this.set_cookie = false;
    }
    if (cookies && cookies != null && cookies == 'false') {
      this.set_cookie = false;
    }

    // 2. cookie
    if (
      (!session || session == null) &&
      this.$cookies.isKey(COOKIE_NAME_SESSION)
    ) {
      session = this.$cookies.get(COOKIE_NAME_SESSION);
    }

    // validera session om vi hittat nån
    if (session && session != null) {
      var obj = Q.ip_verify_token_obj();
      obj.Q.session = session;
      obj = Q.clean_null_obj(obj);

      //console.log('App','beforeMount',5,this.is_item_loaded)
      var response = await this.$store.dispatch(
        'infopingstore/fetch_verify_token',
        obj
      );
      //  .then(async (response) => {
      //console.log('App','beforeMount',6,this.is_item_loaded)
      if (response.status == '1') {
        await this.fetchLoggedIn(session);
      } else {
        //console.log("No valid session in url or cookie");
        this.urlHasSeesion = false;
        this.showLogin = true;
        this.$cookies.remove(COOKIE_NAME_SESSION);
      }

      //console.log('App','beforeMount','7a',this.is_item_loaded)
      //   });
    } else {
      // 3. ingen session hittad    => inloggning
      //console.log("No session found in url or cookie");
      this.urlHasSeesion = false;
      this.showLogin = true;
      this.$cookies.remove(COOKIE_NAME_SESSION);
      //console.log('App','beforeMount','7b',this.is_item_loaded)
    }
  },
  methods: {
    async loggedIn(session) {
      if (session && session != null) {
        //kaka på kaka att köra verify_token när vi precis loggat in men behöver telefonnumret (i rätt format)
        var obj = Q.ip_verify_token_obj();
        obj.Q.session = session;
        obj = Q.clean_null_obj(obj);

        await this.$store.dispatch('infopingstore/fetch_verify_token', obj);
        await this.fetchLoggedIn(session);
      }
    },

    async fetchLoggedIn(session) {
      //console.log('loggedIn', session);
      // SPARAR SESSIONEN I STORE
      //console.log('fetchLoggedIn',1)
      await this.$store.dispatch('infopingstore/fetch_session', session);
      // SPARAR SESSIONEN I COOKIE

      //console.log('fetchLoggedIn',2)
      if (this.set_cookie == true)
        await this.$cookies.set(COOKIE_NAME_SESSION, session);

      // hämtar org_key

      await this.$store.dispatch(
        'infopingstore/fetch_org_branding_session',
        session
      );

      await this.$store.dispatch('infopingstore/check_access', session);

      this.urlHasSeesion = true;
      this.showLogin = false;

      // GET VISNINGAR
      var obj = Q.vitec_batch_list();
      obj.Q.session = session;
      obj.Q.searchStr = '';
      obj = Q.clean_null_obj(obj);

      //console.log('fetchLoggedIn',7)
      await this.$store.dispatch('infopingstore/fetch_vitec_batch_list', obj);
      this.is_item_loaded = true;

      //this.$store.dispatch('infopingstore/fetch_vitec_batch_list_all', obj);
    },
    async lang_chk(lang) {
      return await rest2Api.lang_chk(lang);
    },
    async fetch_source_id(id) {
      await this.$store.dispatch('infopingstore/fetch_source_id', id);
    },
    async fetch_source_name(name) {
      await this.$store.dispatch('infopingstore/fetch_source_name', name);
    },
    async fetch_source_number(number) {
      await this.$store.dispatch('infopingstore/fetch_source_number', number);
    },
    async fetchLang() {
      await this.$store.dispatch('infopingstore/fetch_lang_filter', {
        lang: this.lang,
        filter: 'all',
      });
    },
    themeColor() {
      //funkar bara att hämta från main css...
      if (
        this.org_key == '2785' // vitec '2673'
      )
        return '#262933';
      else if (this.org_key == '2417') return '#dedbd5';
      else return '#121212';
    },

    setTheme() {
      if (
        this.org_key == '2283' || //notar
        this.org_key == '2785' || //ERA
        this.org_key == '2673' || //Vitec
        this.org_key == '1330'
      )
        this.$vuetify.theme.dark = true;
      else this.$vuetify.theme.dark = false;

      if (this.$vuetify.theme.dark == true) {
        //    <!--<meta name="theme-color" content="#ff7a18">-->
        var meta = document.createElement('meta');
        meta.name = 'theme-color';
        meta.content = this.themeColor();
        document.getElementsByTagName('head')[0].appendChild(meta);
        var bdy = document.getElementsByTagName('body')[0];
        bdy.style.backgroundColor = this.themeColor();
      }
    },
  },
};
</script>
<style lang="scss">
#app {
  font-family: var(--font-family);
}
</style>
