<template>
  <div
    :class="{ desktopView: $vuetify.breakpoint.mdAndUp && not_StartViewing() }"
  >
    <!--   active-class="activeTab"  continous-->
    <div v-if="not_StartViewing()">
      <v-card
        flat
        :style="
          $vuetify.breakpoint.xs ? 'margin: var(--page-margins-mobile);' : ''
        "
      >
        <v-tabs
          style="padding: 5px"
          v-model="tab"
          center-active
          show-arrows
          flat
          hide-slider
          class="smallArrowsprev smallArrowsnext"
        >
          <!-- 
class: Magi. Om darkmode inte vit text. Men på den aktiva knappen ska det vara vit text            :class="[
                darkMode
                  ? i == menuItems[tab]
                    ? 'white--text'
                    : 'secondary--text'
                  : 'white--text',
              ]"-->

          <v-tab v-for="i in menuItems" :key="i">
            <v-btn
              :style="{
                color:
                  i == menuItems[tab]
                    ? 'var(--menu-tabs-active-text-color)'
                    : 'var(--menu-tabs-text-color)',
              }"
              @click="clickedTab(i)"
              small
              color="var(--menu-tabs-bg-color)"
              :outlined="i == menuItems[tab]"
            >
              {{ i }}</v-btn
            >
          </v-tab>
        </v-tabs>
      </v-card>
    </div>
    <div>
      <!-- <Participants v-if="page_participants"/>
   <QR v-if="page_qr"/> -->
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
// import Participants from '../components/Participants.vue'
// import QR from '../components/QR.vue'
import Q from '@/questions.js';
import get from 'lodash/get';
import componentsMixin from './componentsMixin.js';
//import rest2Api from '@/api/rest2Api.js';
export default {
  name: 'SelectedViewing',
  mixins: [componentsMixin],
  props: {},
  watch: {},

  components: {
    // Participants,
    // QR ,
    // StartViewing,
  },
  data() {
    return {
      menuItems: [
        this.getLang('v6_viewing', 'lbl_viewings'),
        this.getLang('v6_viewing', 'lbl_participants'),
        this.getLang('v6_viewing', 'lbl_qr'),
        this.getLang('v6_viewing', 'lbl_self'),
        this.getLang('v6_viewing', 'lbl_smsansw'),
      ],
      tab: 1,

      show_meny: true,
    };
  },
  beforeMount() {
    this.checkTab();
  },
  beforeUpdate() {},

  computed: {},

  methods: {
    clickedTab(i) {
      //console.log('tab', i);

      if (i == this.getLang('v6_viewing', 'lbl_viewings'))
        this.click_viewings();
      else if (i == this.getLang('v6_viewing', 'lbl_participants'))
        this.click_participants();
      else if (i == this.getLang('v6_viewing', 'lbl_qr')) this.click_qr();
      else if (i == this.getLang('v6_viewing', 'lbl_self'))
        this.click_start_viewing();
      else if (i == this.getLang('v6_viewing', 'lbl_smsansw'))
        this.click_answers();
    },

    checkTab() {
      //when reload page
      if (this.$route.name == 'QR') this.tab = 2;
      else if (this.$route.name == 'Answers') this.tab = 4;
    },

    click_viewings() {
      var item = get(
        this.$store.state.infopingstore,
        'selected_viewing.item',
        null
      );
      if (item != null) {
        this.viewing_start_end(item.batch_key, item.batch_name, 'end');
      }
      // töm selected viewing
      this.$store.dispatch('infopingstore/fetch_selected_viewing');
      //töm permissions?
      this.$store.dispatch('infopingstore/fetch_permissions');

      // GET VISNINGAR
      var session = get(this.$store.state.infopingstore, 'session', '');
      var obj = Q.vitec_batch_list();

      obj.Q.session = session;
      obj.Q.searchStr = '';
      obj = Q.clean_null_obj(obj);

      this.$store.dispatch('infopingstore/fetch_vitec_batch_list', obj);

      // this.$store.dispatch('infopingstore/fetch_vitec_batch_list_all', obj);

      this.$router.push({
        path: '/Viewings',
        //query: { page: 'Viewings' },
      });
    },
    click_participants() {
      this.$router.push({
        path: '/SelectedViewing/' + this.$route.params.id + '/Participants',
        //query: { page: 'Viewings' },
      });
    },
    click_answers() {
      this.$router.push({
        path: '/SelectedViewing/' + this.$route.params.id + '/Answers',
      });
    },
    click_qr() {
      var batch_key = get(
        this.$store.state.infopingstore,
        'selected_viewing.item.batch_key',
        null
      );

      if (batch_key == null || batch_key == '0') {
        this.alertDialogNoCancel(
          'Visningen hittas inte',
          'Vänligen välj visningen på nytt i listan.',

          () => console.log('user ok')
        );
      } else {
        this.$router.push({
          path: '/SelectedViewing/' + this.$route.params.id + '/QR',
          //query: { page: 'Viewings' },
        });
      }
    },

    click_start_viewing() {
      var batch_key = get(
        this.$store.state.infopingstore,
        'selected_viewing.item.batch_key',
        null
      );

      if (batch_key == null || batch_key == '0') {
        this.alertDialogNoCancel(
          this.getLang('v6_viewing', 'lbl_notfound'),
          this.getLang('v6_viewing', 'txt_notfound'),

          () => console.log('user ok')
        );
      } else {
        this.$store.dispatch('infopingstore/fetch_viewing_started', true);
        this.$router.push({
          path: 'StartViewing',
          //query: { page: 'StartViewing' },
        });
      }
    },

    alertDialogNoCancel(headerText, bodyText, okFunc) {
      this.$store.dispatch('infopingstore/showAlert', {
        show: true,
        headerText: headerText,
        bodyText: bodyText,
        showCancelButton: false,
        confirmAction: () => {
          okFunc();
        },
      });
    },
  },
};
</script>
<style scoped>
/* ta bort flex?
.v-slide-group:not(.v-slide-group--has-affixes) .v-slide-group__next {
  display: flex;
} 
*/

.smallArrowsnext ::v-deep .v-slide-group__next,
.v-slide-group__prev {
  min-width: 5px;
}

.smallArrowsprev ::v-deep .v-slide-group__prev {
  min-width: 5px;
}

.v-tab:before {
  background-color: transparent;
}
</style>
