import {
  LIST_VIEWINGS,
  LIST_ALL_VIEWINGS,
  SELECTED_VIEWING,
  VIEWING_PARTICIPANTS,
  VIEWING_BROKER,
  VERIFY_TOKEN,
  SESSION,
  ORG_BRANDING,
  VIEWING_STARTED,
  //BRANDING,
  LANG_FILTER,
  VITEC,
 

//OFFICES,
BROKERS,
  HEADER_NAME,
  VIEWING_HOST,
  BATCH,
  PERMISSIONS,
  SHOW_ALERT,
  ACCESS,
  USER_TYPE,
  SOURCE_ID,
  SOURCE_NAME,
  SOURCE_NUMBER,

} from './types.js';

export default {
  //----------------------------------------------
  // VISNINGSASSISTENTEN
  //----------------------------------------------
  [SOURCE_ID](state, payload) {
    state.source_id = payload;
  }, 
  [SOURCE_NAME](state, payload) {
    state.source_name = payload;
  },
  [SOURCE_NUMBER](state, payload) {
    state.source_number = payload;
  },
  //----------------------------------------------
  // VITEC
  //----------------------------------------------
  [HEADER_NAME](state, payload) {
    state.header_name = payload;
  },
  [LIST_VIEWINGS](state, payload) {
    state.list_viewings = payload;
  },
[LIST_ALL_VIEWINGS](state, payload) {
  state.list_all_viewings = payload;
},
  [SELECTED_VIEWING](state, payload) {
    state.selected_viewing = payload;
  },
  [VIEWING_PARTICIPANTS](state, payload) {
    state.viewing_participants = payload;
  },
  [VIEWING_BROKER](state, payload) {
    state.viewing_broker = payload;
  },
  [VIEWING_STARTED](state, payload) {
    state.viewing_started = payload;
  },

 
  // [OFFICES](state, payload) {
  //   state.offices = payload;
  // },
  [BROKERS](state, payload) {
    state.brokers = payload;
  },
  [VIEWING_HOST](state, payload) {
    state.viewing_host = payload;
  },
  [PERMISSIONS](state, payload) {
    state.permissions = payload;
  },
  //----------------------------------------------
  // INFOPING
  //----------------------------------------------
  
  [VERIFY_TOKEN](state, payload) {
    state.verify_token = payload;
  },
  [SESSION](state, payload) {
    state.session = payload;
  },
  [ORG_BRANDING](state, payload) {
    state.org_branding = payload;
  },
  [BATCH](state, payload) {
    state.batch = payload;
  },
  [ACCESS](state, payload) {
    state.access = payload;
  },
  [USER_TYPE](state, payload) {
    state.user_type = payload;
  },
  [VITEC](state, payload) {
    state.vitec_data = payload;
  },

    //----------------------------------------------
  // LAYOUT
  //----------------------------------------------
  
  [SHOW_ALERT](state,alertData) {
    state.alertData=alertData;
  },

  [LANG_FILTER](state,payload) {
    state.lang_filter=payload;
  },
};
