import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export default {
  namespaced: true,
  state: {
    list_viewings: [],
    list_all_viewings: [],
    selected_viewing: [],
    viewing_participants: [],
    viewing_broker: [],
    verify_token: [],
    user_type: null,
   // branding: [],
    session: "",
    org_branding: { org_key: null, custom_logo: null, },
    viewing_started: false,
    // offices:[],
    source_id: "",
    source_name: "",
    source_number: "",
    brokers: [],
    header_name: "",
    viewing_host: [],
    batch: [],
    access: [],
    permissions: [],
    //simple Alert dialog
    alertData: {
      show: false,
      headerText: "Alert",
      bodyText: "This is a simple alert",
      showCancelButton: true,
      confirmAction: () => {},
      cancelAction: () => {},
    },

    lang_filter: null,
  },
  vitec_data: null,
  actions,
  getters,
  mutations,
};
