import get from 'lodash/get';

export default {


//hämtar alla orgs under vald access, tex share_brokers, report...
      access_orgs: (state) => (theaccess) => {
    
      var access =  get(state, 'access', []);
    
  
      var reports = access.filter(function(obj){

       return (obj.name.includes(theaccess)  );
      });
   
      var orgs = [];
      if (reports.length > 0) {
        reports.forEach((reportOrgs) => {
          reportOrgs.orgs.forEach((element) => {
            var obj = {
              name: element.name,
              org_key: element.org_key,
              //lägg till kontor här
            };
            // om orgs inte innehåller org_key lägg till objektet vi skapat
            if (!orgs.some((item) => item.org_key === obj.org_key))
              orgs.push(obj);
          });
        });
      }


           // sortera array med objekt
    orgs.sort((a, b) =>
    a.name > b.name ? 1 : b.name > a.name ? -1 : 0
  );

   
      return orgs;

 
},

broker_offices: (state)  => (access, org_key) => {


  var accessList =  get(state, 'access', []);


  var accessObject = accessList.filter(function(obj){
   

   return (obj.name.includes(access)  );
  });



  var offices = [];
  if (accessObject.length > 0) {
    accessObject.forEach((org) => {
      org.orgs.forEach((org2) => {
        if (org2.org_key == org_key)
        org2.customers.forEach((office) => {

        var obj = {
          name: office.name + ' - ' + office.customerId,
          customerId: office.customerId,
        };
        // om orgs inte innehåller org_key lägg till objektet vi skapat
        if (!offices.some((item) => item.customerId === obj.customerId))
          offices.push(obj);
      });

    });
    });
  }

      // sortera array med objekt
    offices.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );


return offices;

},

};