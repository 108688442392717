<template>
  <div>
    <v-container
      :class="{ desktopView: $vuetify.breakpoint.mdAndUp }"
      style="padding: 0px; margin-top: 50px;"
    >
      <div style="padding: 20px;" class="borderContainer">
        <div>
          <v-row no-gutters class="center">
            <label style="font-size: 1.2 em; font-weight: bold;">
              {{ this.getLang('v6_viewing', 'lbl_smsreg') }}
            </label>
          </v-row>

          <div style="padding: 10px;" class="borderContainer">
            <v-row no-gutters class="center">
              <label style="font-size: 0.8 em">
                {{ this.getLang('v6_viewing', 'txt_smsreg') }}
              </label>
            </v-row>

            <v-row no-gutters align="center" justify="center">
              <br />
              <label style="font-size: 12px">
                {{ this.getLang('v6_viewing', 'lbl_codeshowing') }}:
              </label>
              <label style="font-size: 12px"> {{ get_ping_text() }}</label>
            </v-row>
            <br />

            <v-row no-gutters justify="center">
              <v-btn
                color="var(--menu-tabs-bg-color)"
                style="color:var(--menu-tabs-text-color)"
                width="95%"
                @click="print"
              >
                {{ this.getLang('v6_viewing', 'lbl_print') }}
              </v-btn>
            </v-row>
          </div>
        </div>
      </div>
    </v-container>
    <!-- PRINTSIDAN-->

    <v-card elevation="0" id="printMe" v-show="printPage">
      <div v-if="this.batch_key" class="qr-v-card center-print">
        <div :class="{ QRbackground: printBackground }">
          <div class="child-qr-v-card">
            <br />
            <br />

            <img :class="get_logo_url()" style="max-width: 300px;" />
            <br />
            <br />
            <br />
            <label
              :class="getTextFont()"
              style="font-size: 1.4em;   letter-spacing: .2rem;"
            >
              {{
                this.getLang('v6_viewing', 'lbl_registersms').toUpperCase()
              }}</label
            >
            <br />
            <br />
            <label
              :class="getHeaderFont()"
              style="font-size: 2.7em; width: 600px"
            >
              {{ this.getLang('v6_viewing', 'txt_registersms') }}</label
            >
            <br />
            <label
              :class="getHeaderFont()"
              style="font-size: 2.7em; width: 600px"
            >
              {{ this.getLang('v6_viewing', 'txt_registersms2') }}</label
            >
            <br />
            <br />
            <br />

            <qr-code
              v-if="this.batch_key"
              :text="smsText"
              :size="250"
              color="#000000"
              error-level="L"
              class="qr-code"
              bg-color="transparent"
            ></qr-code>

            <br />
            <br />
            <label
              :class="getTextFont()"
              style="font-size: 2.1em; font-weight: bold;"
            >
              {{ this.getLang('v6_viewing', 'lbl_smsto') }}</label
            >

            <br />
            <br />
            <br />
            <label
              :class="getTextFont()"
              style="font-size: 1.5em;text-align: center"
              >{{ this.getLang('v6_viewing', 'lbl_smstext') }}:
            </label>
            <br />

            <label
              :class="getTextFont()"
              style="font-size: 1.6em; font-weight: bold"
              >{{ get_ping_text() }}</label
            >
            <br />
            <label
              :class="getTextFont()"
              style="font-size: 1.6em; font-weight: bold"
              >{{ get_ping_text2() }}</label
            >
            <br />
            <br />
            <br />
            <label
              :class="getTextFont()"
              style="font-size: 1.1em; letter-spacing: normal; font-style: italic;"
              ><i>{{ this.getLang('v6_viewing', 'lbl_smsprivacy') }} </i>
            </label>
            <br />
            <br />
            <br />

            <label
              :class="getTextFont()"
              style="font-size: 0.8em; letter-spacing: normal;"
              >{{ get_batch_name() }}</label
            >

            <br />
            <label
              :class="getTextFont()"
              v-if="get_broker_name()"
              style="font-size: 0.9em;"
              >Mäklare:
            </label>
            <label :class="getTextFont()" style="font-size: 0.8em;">{{
              get_broker_name()
            }}</label>
            <br />
            <br />
          </div>
        </div>
      </div>
      <div v-else style="margin-top: 50px">
        <v-row no-gutters class="center-print">
          <label style="font-size: 1.1em; font-weight: bold; color: red;">
            {{ this.getLang('v6_viewing', 'lbl_errornobatchkey') }}</label
          >
        </v-row>
      </div>
    </v-card>

    <!-- slut printsidan-->
    <!--  </v-dialog>-->
  </div>
</template>

<script>
import get from 'lodash/get';
import componentsMixin from './componentsMixin.js';

export default {
  name: 'qr',
  mixins: [componentsMixin],
  props: {},

  components: {
    // VueQRCodeComponent,
  },
  data() {
    return {
      output: null,
      icon_round: true,
      printPage: false,
    };
  },
  watch: {},

  computed: {
    printBackground() {
      var org_key = get(
        this.$store.state.infopingstore.selected_viewing,
        'item.org_key',
        null
      );
      if (org_key == '2282') return true;
      else return false;
    },

    batch_key() {
      return get(
        this.$store.state.infopingstore.selected_viewing,
        'item.batch_key',
        null
      );
    },

    smsText() {
      var result = '';

      var org_key = get(
        this.$store.state.infopingstore.selected_viewing,
        'item.org_key',
        null
      );
      var visning_qr = get(
        this.$store.state.infopingstore,
        'org_branding.qr',
        ''
      );

      var smsTo = 'smsto:71611:';
      var smsLeadingTextBatchKey =
        'VISNING ' + 'AA-' + this.batch_key.toString() + '\n';
      var smsStandardTextWithEmail =
        smsTo + smsLeadingTextBatchKey + 'Ditt namn och epost:';
      var smsStandardTextNoEmail =
        smsTo + smsLeadingTextBatchKey + 'Ditt namn:';
      var smsSpecialTextWithEmail =
        smsTo +
        smsLeadingTextBatchKey +
        'Välkommen på visning på' +
        ' ' +
        this.get_streetAddress() +
        '.' +
        ' ' +
        'Checka in med ditt namn och epost:';
      var smsSpecialTextNoEmail =
        smsTo +
        smsLeadingTextBatchKey +
        'Välkommen på visning på' +
        ' ' +
        this.get_streetAddress() +
        '.' +
        ' ' +
        'Checka in med ditt namn:';

      // OBS SPECIALTEXT FÖR ORG_KEY 2283 i den genererade SMS-TEXTEN från QR-koden
      if (org_key != '2283') {
        result =
          visning_qr == 'epost'
            ? smsStandardTextWithEmail
            : smsStandardTextNoEmail;
      } else {
        result =
          visning_qr == 'epost'
            ? smsSpecialTextWithEmail
            : smsSpecialTextNoEmail;
      }
      //console.log('qr',result)
      return result;
    },
  },
  methods: {
    get_ping_text() {
      if (this.batch_key != null) {
        return 'Visning aa-' + this.batch_key.toString();
      } else return this.getLang('v6_viewing', 'lbl_codeerror');
    },
    get_ping_text2() {
      var result = '';

      var visning_qr = get(
        this.$store.state.infopingstore,
        'org_branding.qr',
        ''
      );
      if (this.batch_key != null) {
        result = this.getLang('v6_viewing', 'lbl_smsname');
        if (visning_qr == 'epost')
          result = result + ' ' + this.getLang('v6_viewing', 'lbl_smsmail');
      }
      return result;
    },
    get_batch_name() {
      var batch_name = get(
        this.$store.state.infopingstore.selected_viewing,
        'item.batch_name',
        null
      );
      if (batch_name != null) {
        return batch_name;
      } else return this.getLang('v6_viewing', 'lbl_someerror');
    },

    get_broker_name() {
      return get(
        this.$store.state.infopingstore,
        'viewing_broker.userName',
        null
      );
    },
    get_streetAddress() {
      var streetAddress = get(
        this.$store.state.infopingstore.selected_viewing,
        'item.streetAddress',
        null
      );
      if (streetAddress != null) {
        return streetAddress;
      } else return this.getLang('v6_viewing', 'lbl_someerror');
    },
    print() {
      // Pass the element id here

      const options = {
        name: '_blank',
        specs: ['fullscreen=no', 'titlebar=no', 'scrollbars=no'],
        styles: ['/main.css'],
      };
      this.$htmlToPaper('printMe', options);

      // this.printPage = true;
    },

    // branding() {
    //   var color =
    //     'color: #' +
    //     get(
    //       this.$store.state.infopingstore,
    //       'org_branding.back_color',
    //       '323232'
    //     );

    //   return color;
    // },
    getTextFont() {
      var org_key = get(
        this.$store.state.infopingstore.selected_viewing,
        'item.org_key',
        null
      );
      switch (org_key) {
        case '2282':
          return 'EOText';
        case '2283':
          return 'NotarFont';
        case '2417':
          return 'SMHFont';
        default:
          return 'defaultFont';
      }
    },
    getHeaderFont() {
      var org_key = get(
        this.$store.state.infopingstore.selected_viewing,
        'item.org_key',
        null
      );
      switch (org_key) {
        case '2282':
          return 'EOheader';
        case '2283':
          return 'NotarFont';
        case '2417':
          return 'SMHFont';
        default:
          return 'defaultFont';
      }
    },
  },
  beforeMount() {
    var icon_round = get(
      this.$store.state.infopingstore,
      'org_branding.header_icon',
      ''
    );

    if (icon_round == '' || icon_round == 'round') {
      this.icon_round = true;
    } else this.icon_round = false;
  },
  async mounted() {
    //gick inte att ha detta i selected viewing pga timingproblem.
    //funkar inte att watcha storen, kollar här om det är en reload (skulle helst göra det när visningarna finns i storen)

    if (!this.batch_key) {
      var res = await this.setDefaultViewing(this.$route.params.id);
      if (res == 'found viewing') console.log('ok');
      else {
        this.alertDialogNoCancel(
          this.getLang('v6_viewing', 'lbl_notfound'),
          this.getLang('v6_viewing', 'txt_notfound'),

          () => console.log('user ok')
        );
      }
    }
  },
};
</script>
<style scoped>
/* Import Google Font
@import url('https://fonts.googleapis.com/css2?family=Raleway');

@media print {
  body {
    font-family: 'Raleway';
  }
  html {
    width: 100%;
    height: auto;
  }
} */
</style>
