import Vuex from 'vuex';
import Vue from 'vue';
import infopingstore from './infopingstore';


Vue.use(Vuex);


export default () =>
  new Vuex.Store({
    modules: { infopingstore },
    strict: process.env.NODE_ENV !== 'production',


  });
