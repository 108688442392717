<template>
  <div :class="{ desktopView: $vuetify.breakpoint.mdAndUp }">
    <!--  outlined     :elevation="$vuetify.breakpoint.mdAndUp ? '1' : '0'"-->
    <v-card
      flat
      :style="
        $vuetify.breakpoint.xs
          ? 'margin: var(--page-margins-mobile); padding-top: 20px; margin-top: -3px;'
          : 'padding-top: 20px; margin-top: -3px'
      "
    >
      <!-- USER CARD       -->
      <v-dialog
        v-model="userDialog"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        height="100vh"
        overlay-color="transparent"
        max-width="800px"
      >
        <v-card style="padding-top: 20px;">
          <v-card-text>
            <!-- :class="{ 'pd-zero': $vuetify.breakpoint.xs }"-->
            <v-container class="borderContainer">
              <v-row>
                <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                  <v-text-field
                    class="inputClass"
                    outlined
                    hide-details
                    v-model="participant.cellPhone"
                    :label="labelPhone()"
                    v-on:blur="lookup_contact"
                    :disabled="not_edit"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                  <v-text-field
                    class="inputClass"
                    outlined
                    hide-details
                    v-model="participant.email"
                    :label="labelEmail()"
                    v-on:blur="/*run_lookup()*/"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                  <v-text-field
                    class="inputClass"
                    outlined
                    hide-details
                    v-model="participant.first_name"
                    :label="
                      this.getLang('v6_viewing', 'lbl_firstname').toUpperCase()
                    "
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                  <v-text-field
                    outlined
                    hide-details
                    class="inputClass"
                    v-model="participant.last_name"
                    :label="
                      this.getLang('v6_viewing', 'lbl_lastname').toUpperCase()
                    "
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-textarea
                    wrap
                    outlined
                    class="inputClass"
                    hide-details
                    v-model="participant.note"
                    :label="
                      this.getLang('v6_viewing', 'lbl_note').toUpperCase()
                    "
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6" class="pd-chkbox">
                  <v-checkbox
                    :label="
                      this.getLang(
                        'v6_viewing',
                        'lbl_participated'
                      ).toUpperCase()
                    "
                    false-value="0"
                    true-value="1"
                    v-model="participant.attended"
                  ></v-checkbox>
                </v-col>

                <v-col cols="6" class="pd-chkbox">
                  <v-checkbox
                    :label="
                      this.getLang('v6_viewing', 'lbl_valuation').toUpperCase()
                    "
                    false-value="0"
                    true-value="1"
                    v-model="participant.valuation"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-label v-if="orgKey == '2283'"
                >*obligatoriska uppgifter</v-label
              >
              <!---   <v-row>
                <v-col cols="0" class="pd-zero">
                  <v-text-field
                    class="hiddenArea pd-zero"
                    v-model="theTest"
                  ></v-text-field>
                </v-col>
              </v-row>-->
            </v-container>
          </v-card-text>

          <v-card-actions style="margin-top:15px">
            <v-btn text @click="close">{{
              this.getLang('v6_viewing', 'lbl_cancel')
            }}</v-btn>
            <!--<v-spacer></v-spacer>-->
            <v-spacer></v-spacer>
            <v-btn text @click="save"
              >{{ this.getLang('v6_viewing', 'lbl_save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <div>
        <v-list subheader two-line style="width: 100%; height: 100vh;">
          <!--  <v-divider></v-divider>-->

          <v-row no-gutters style="margin-left: 35px; margin-right: 30px">
            <v-col dense cols="9" xl="3" lg="3" md="3" sm="3">
              <span v-on:click="changeSortOrder">{{
                this.getLang('v6_viewing', 'lbl_name').toUpperCase()
              }}</span>
            </v-col>
            <v-col
              @click="listItemClicked(item)"
              v-if="$vuetify.breakpoint.smAndUp"
              cols="2"
            >
              <v-list-item-content>
                <v-list-item-subtitle
                  style="font-weight: bold"
                ></v-list-item-subtitle> </v-list-item-content
            ></v-col>
            <v-col v-if="$vuetify.breakpoint.smAndUp" cols="2">
              {{
                this.getLang('v6_viewing', 'lbl_bookedheader').toUpperCase()
              }}</v-col
            >

            <v-col v-if="$vuetify.breakpoint.smAndUp" cols="3">
              {{ this.getLang('v6_viewing', 'lbl_note').toUpperCase() }}</v-col
            >

            <v-col cols="2" xl="1" lg="1" md="1" sm="1">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="participant-icon"
                    dense
                    v-bind="attrs"
                    v-on="on"
                    >mdi-account-check</v-icon
                  >
                </template>
                <span>{{
                  this.getLang('v6_viewing', 'lbl_participated')
                }}</span>
              </v-tooltip>
            </v-col>
            <v-col cols="1" xl="1" lg="1" md="1" sm="1">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="participant-icon"
                    dense
                    v-bind="attrs"
                    v-on="on"
                    >mdi-currency-eur</v-icon
                  >
                </template>
                <span>{{ this.getLang('v6_viewing', 'lbl_valuation') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>

          <!--  <v-divider></v-divider>-->
          <v-list-item-group>
            <template v-for="item in participants_filtered">
              <v-list-item class="borderContainer" :key="item.contactId">
                <!--:style="get_style_background(item)"-->

                <template>
                  <v-row dense>
                    <v-col cols="9" xl="3" lg="3" md="3" sm="3">
                      <v-list-item-content>
                        <!--  @click="edit_participant(item)"-->
                        <v-list-item-subtitle
                          @click="listItemClicked(item)"
                          style="font-weight: bold"
                          v-text="item.user_name"
                        ></v-list-item-subtitle>
                        <!--   @click="edit_participant(item)"-->
                        <a :href="'tel:' + item.phone_number">{{
                          item.phone_number
                        }}</a>
                      </v-list-item-content>
                    </v-col>
                    <v-col
                      @click="listItemClicked(item)"
                      v-if="$vuetify.breakpoint.smAndUp"
                      cols="2"
                    >
                      <v-list-item-content>
                        <v-list-item-subtitle
                          style="font-weight: bold"
                        ></v-list-item-subtitle> </v-list-item-content
                    ></v-col>
                    <!--  @click="edit_participant(item)"-->
                    <v-col
                      @click="listItemClicked(item)"
                      v-if="$vuetify.breakpoint.smAndUp"
                      cols="2"
                    >
                      <v-list-item-content>
                        <v-list-item-subtitle
                          style="font-weight: bold"
                          v-text="item.time_slot"
                        ></v-list-item-subtitle> </v-list-item-content
                    ></v-col>
                    <!--  @click="edit_participant(item)"-->
                    <v-col
                      @click="listItemClicked(item)"
                      v-if="$vuetify.breakpoint.smAndUp"
                      cols="3"
                    >
                      <v-list-item-content>
                        <v-list-item-subtitle
                          style="font-weight: bold"
                          v-text="item.note"
                        ></v-list-item-subtitle> </v-list-item-content
                    ></v-col>

                    <!--   <v-spacer v-if="$vuetify.breakpoint.xs"></v-spacer>-->
                    <v-col cols="2" xl="1" lg="1" md="1" sm="1">
                      <v-checkbox
                        v-model="item.attended"
                        dense
                        false-value="0"
                        true-value="1"
                        v-on:change="upd_attended(item.attended, item)"
                      ></v-checkbox>
                    </v-col>

                    <v-col cols="1" xl="1" lg="1" md="1" sm="1">
                      <v-checkbox
                        v-model="item.valuation"
                        dense
                        false-value="0"
                        true-value="1"
                        v-on:change="upd_valuation(item.valuation, item)"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </template>
              </v-list-item>
              <!--   <v-divider
                v-if="index + 1 < participants_filtered.length"
                :key="index"
              ></v-divider>-->
            </template>
          </v-list-item-group>
          <div id="spacer" style="min-height:175px">&nbsp;</div>
        </v-list>
      </div>
    </v-card>
    <!--  <theFooter v-model="show"></theFooter>-->

    <template>
      <v-footer
        fixed
        :class="{ desktopView: $vuetify.breakpoint.mdAndUp }"
        style="border-top: 1px solid #d2d2d2; padding: 0px 10px 0px 10px"
      >
        <!-- <div id="fixedFooter">-->
        <v-col style="padding: 0px" class="text-center" cols="12">
          <v-row>
            <v-col style="padding: 0px;" cols="6">
              <label
                style="display: flex; justify-content: flex-start;  font-size: 14px; padding-top: 10px; padding-left: 15px"
              >
                {{ this.getLang('v6_viewing', 'lbl_nbrparticipants') }}:
                {{ nbr_attended }}
              </label>
            </v-col>
            <v-col style="padding: 0px;" cols="6">
              <!--     <label v-if="!verySmall" style="font-size: 12px">
            Antal som deltagit: {{ nbr_attended }}
          </label>-->
              <span style="display: flex; justify-content: flex-end;">
                <v-btn
                  style="padding: 0px 10px 0px 0px;"
                  text
                  color="primary"
                  @click="new_participant"
                  >{{ this.getLang('v6_viewing', 'lbl_add')
                  }}<v-icon>mdi-plus</v-icon></v-btn
                >
              </span>
            </v-col>
          </v-row>
          <!--        v-model="search"  <v-btn icon elevation="0" @click="upd_participants()">
              <v-icon class="icon-refresh">mdi-refresh</v-icon>
            </v-btn>-->

          <v-text-field
            solo
            dense
            background-color="var(--search-field-bg-color)"
            style="margin-bottom: 10px"
            flat
            v-model="search"
            append-icon="mdi-magnify"
            :label="this.getLang('v6_viewing', 'lbl_searchparticipant')"
            single-line
            hide-details
            @keyup="enterpushed"
            clearable
            @click:clear="clearSearch()"
          >
          </v-text-field>
        </v-col>
      </v-footer>
    </template>
  </div>
</template>

<script>
import Q from '@/questions.js';
import get from 'lodash/get';
import componentsMixin from './componentsMixin.js';
import rest2Api from '@/api/rest2Api.js';
//import theFooter from './footer.vue';
export default {
  name: 'participants',
  mixins: [componentsMixin],
  watch: {
    // dialog(val) {
    //   val || this.close();
    // },
  },

  data() {
    return {
      participant: {
        phone_number: null,
        first_name: null,
        last_name: null,
        email: null,
        note: null,
        attended: null,
        valuation: null,
        vitec_contact_id: null,
        cellPhone: null,
      },
      userDialog: false,
      nbr_attended: null,
      search: '',

      time_slot: true,
      last_mobile: '',
      batch_gen_items_key: '',
      not_edit: false,

      sortByName: null,
      //  save_disabled: true,
    };
  },
  components: {}, //theFooter

  beforeMount() {
    //var batch_key = get(this.$store.state.infopingstore,'selected_viewing.item.batch_key',0);
    if (this.batch_key) this.get_viewing_participants();
  },
  async mounted() {
    //funkar inte att watcha storen, kollar här om det är en reload (skulle helst göra det när visningarna finns i storen)

    if (!this.batch_key) {
      var res = await this.setDefaultViewing(this.$route.params.id);
      if (res == 'found viewing') this.get_viewing_participants();
      else {
        this.alertDialogNoCancel(
          this.getLang('v6_viewing', 'lbl_notfound'),
          this.getLang('v6_viewing', 'txt_notfound'),

          () => console.log('user ok')
        );
      }
    }

    if (this.participants_filtered && this.participants_filtered.length == 0) {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
  },
  destroyed() {
    this.$store.dispatch('infopingstore/clear_participants');
  },
  computed: {
    orgKey() {
      return get(
        this.$store.state.infopingstore,
        'selected_viewing.item.org_key',
        null
      );
    },

    batch_key() {
      return get(
        this.$store.state.infopingstore,
        'selected_viewing.item.batch_key',
        null
      );
    },

    participants_filtered() {
      var obj = get(
        this.$store.state.infopingstore,
        'viewing_participants',
        []
      );
      var filtered = obj;

      if (this.search) {
        filtered = obj.filter((item) => {
          return (
            item.user_name.toLowerCase().match(this.search.toLowerCase()) ||
            item.phone_number.toLowerCase().match(this.search.toLowerCase())
          );
        });
      }
      var clone = JSON.parse(JSON.stringify(filtered));

      if (this.sortByName == 'ASC')
        return clone.sort((current, next) => {
          return current.user_name.localeCompare(next.user_name);
        });
      if (this.sortByName == 'DESC')
        return clone.sort((current, next) => {
          return next.user_name.localeCompare(current.user_name);
        });

      return clone;
    },
  },
  methods: {
    clearSearch() {
      this.search = '';
    },

    labelEmail() {
      var label = this.getLang('v6_viewing', 'lbl_email').toUpperCase();
      if (this.orgKey == '2283') label = label + '*';

      return label;
    },

    labelPhone() {
      var label = this.getLang('v6_viewing', 'lbl_phone').toUpperCase();
      if (this.orgKey == '2283') label = label + '*';

      return label;
    },

    listItemClicked(item) {
      this.not_edit = true;
      this.participant.cellPhone = item.cellPhone;
      this.participant.first_name = item.firstName;
      this.participant.last_name = item.lastName;
      this.participant.email = item.emailAddress;
      this.participant.note = item.note;
      this.participant.attended = item.attended;
      this.participant.valuation = item.valuation;
      this.participant.vitec_contact_id = item.contactId;

      this.participant.phone_number = item.phone_number;

      this.userDialog = true;
    },

    resetParticipantForm() {
      this.participant.cellPhone = null;
      this.participant.first_name = null;
      this.participant.last_name = null;
      this.participant.email = null;
      this.participant.note = null;
      this.participant.attended = null;
      this.participant.valuation = null;
      this.participant.vitec_contact_id = null;

      this.participant.phone_number = null;
    },
    enterpushed(e) {
      if (e.keyCode == 13) {
        e.target.blur();
      }
    },

    get_participated() {
      var count = 0;
      var attended = get(
        this.$store.state.infopingstore,
        'viewing_participants',
        0
      );

      attended.forEach((element) => {
        count = count + parseInt(element.attended);
      });

      this.nbr_attended = count;
      //return count;
    },

    changeSortOrder() {
      if (this.sortByName == null) this.sortByName = 'ASC';
      else if (this.sortByName == 'ASC') this.sortByName = 'DESC';
      else if (this.sortByName == 'DESC') this.sortByName = null;
    },

    get_viewing_participants() {
      if (this.batch_key) {
        var obj = Q.vitec_batch_obj();
        obj.Q.session = get(this.$store.state.infopingstore, 'session', '');
        obj.Q.batch_key = this.batch_key;
        obj = Q.clean_null_obj(obj);

        this.$store
          .dispatch('infopingstore/fetch_viewing_participants', obj)
          .then((res) => {
            if (res && res == '1') this.get_participated();
            else {
              var errorMsg = '';
              if (res) errorMsg = 'Fel: ' + res;

              this.alertDialogNoCancel(
                'Det gick inte att hämta deltagare',
                errorMsg,

                () => console.log('user ok')
              );
            }
          });
      }
    },

    new_participant() {
      this.not_edit = false;
      if (this.batch_key != null) {
        this.resetParticipantForm();
        ///    this.dialogItem = JSON.parse(JSON.stringify(this.emptyItem));?????
        // this.dialogItem = Object.assign({}, this.emptyItem);
        this.participant.attended = '1';
        //this.save_disabled = true;
        this.userDialog = true;
      } else {
        this.alertDialogNoCancel(
          'Visningen hittas inte',
          'Vänligen välj visningen på nytt i listan.',

          () => console.log('user ok')
        );
      }
    },

    upd_attended(value, item) {
      var obj = Q.vitec_participant_obj();

      obj.Q.attended = value;

      obj.Q.batch_gen_items_key = item.batch_gen_items_key;
      obj.Q.contactId = item.contactId;
      obj.Q.customerId = get(
        this.$store.state.infopingstore,
        'selected_viewing.item.customerId',
        null
      );

      obj.Q.org_key = get(
        this.$store.state.infopingstore,
        'selected_viewing.item.org_key',
        null
      );

      obj.Q.batch_key = get(
        this.$store.state.infopingstore,
        'selected_viewing.item.batch_key',
        null
      );

      obj = Q.clean_null_obj(obj);
      // UPDATE DB AND VITEC
      rest2Api.vitec_participant_obj(obj).then((response) => {
        //OBS! felmeddelande om vi får fel från vitec?? ParticipantUpd-felen <-------------------------
        //om status är 0 kanske man ska lägga upp ett felmeddelandet ist för att hämta.
        if (response) this.get_viewing_participants();
      });

      // borde hämta om listan med deltagare här men det gick för snabbt så attended kryssades ur
      //räknar istället antal deltagande genom att lägga till och dra ifrån
      if (value == 1) this.nbr_attended += 1;
      else if (value == 0) this.nbr_attended--;
    },

    upd_valuation(value, item) {
      //var row = this.participants_filtered[index];

      var obj = Q.vitec_participant_obj();

      obj.Q.valuation = value;

      obj.Q.batch_gen_items_key = item.batch_gen_items_key;
      obj.Q.contactId = item.contactId;
      obj.Q.customerId = get(
        this.$store.state.infopingstore,
        'selected_viewing.item.customerId',
        null
      );

      obj.Q.org_key = get(
        this.$store.state.infopingstore,
        'selected_viewing.item.org_key',
        null
      );

      obj.Q.batch_key = get(
        this.$store.state.infopingstore,
        'selected_viewing.item.batch_key',
        null
      );

      obj = Q.clean_null_obj(obj);

      // UPDATE DB AND VITEC
      rest2Api.vitec_participant_obj(obj).then(() => {
        //varför hämtar vi inte om listan här vi gör det vid upd attended
        //this.get_viewing_participants(response);
      });
    },

    close() {
      this.userDialog = false;
      //this.has_mobile = false;
      this.last_mobile = '';
      this.batch_gen_items_key = '';

      this.resetParticipantForm();

      this.not_edit = true;
    },

    async save() {
      if (!this.is_valid_phone_number(this.participant.cellPhone)) {
        this.alertDialogNoCancel(
          this.getLang('v6_viewing', 'lbl_invalidnumber'),
          this.getLang('v6_viewing', 'txt_invalidnumber'),

          () => console.log('user ok')
        );
        return;
      }

      if (this.orgKey == '2283' && !this.participant.email.includes('@')) {
        this.alertDialogNoCancel(
          '',
          'Epostadress är obligatoriskt',

          () => console.log('user ok')
        );
        return;
      }

      if (this.participant.cellPhone) {
        var obj = Q.vitec_participant_obj();

        obj.Q.contactId = this.participant.vitec_contact_id;

        obj.Q.cellPhone = this.participant.cellPhone;
        obj.Q.emailAddress = this.participant.email;
        obj.Q.lastName = this.participant.last_name;
        obj.Q.firstName = this.participant.first_name;
        obj.Q.attended = this.participant.attended;
        // obj.Q.wish_lone_promise = this.participantValuation
        obj.Q.valuation = this.participant.valuation;
        obj.Q.note = this.participant.note;
        obj.Q.batch_gen_items_key = this.batch_gen_items_key;
        obj.Q.customerId = get(
          this.$store.state.infopingstore,
          'selected_viewing.item.customerId',
          null
        );

        obj.Q.org_key = get(
          this.$store.state.infopingstore,
          'selected_viewing.item.org_key',
          null
        );

        obj.Q.batch_key = get(
          this.$store.state.infopingstore,
          'selected_viewing.item.batch_key',
          null
        );

        // this.userDialog = false;
        obj = Q.clean_null_obj(obj);
        //ev timingproblem där obj tömdes
        var stringObj = JSON.stringify(obj);

        if (obj.Q.batch_key == null || obj.Q.batch_key == '') {
          this.alertDialogNoCancel(
            this.getLang('v6_viewing', 'lbl_error'),
            this.getLang('v6_viewing', 'lbl_infonotsaved') +
              '. ' +
              this.getLang('v6_viewing', 'txt_notfound'),

            () => console.log('user ok')
          );
        } else {
          // UPDATE DB AND VITEC

          const response = await rest2Api.vitec_participant_obj_string(
            stringObj
          );
          if (response.error == 'Network Error') {
            this.alertDialogNoCancel(
              this.getLang('v6_viewing', 'lbl_nonetwork'),
              this.getLang('v6_viewing', 'lbl_infonotsaved'),

              () => console.log('user ok')
            );
          } else if (
            response.status?.toString() == '0' ||
            response.A.status == 0 ||
            response.A.status == '0'
          ) {
            if (response.A?.error == 'batch_key missing') {
              this.alertDialogNoCancel(
                this.getLang('v6_viewing', 'lbl_error'),
                this.getLang('v6_viewing', 'lbl_infonotsaved') +
                  '. ' +
                  this.getLang('v6_viewing', 'txt_notfound'),

                () => console.log('user ok')
              );
            } else {
              this.alertDialogNoCancel(
                'Fel uppstod, ej sparad',
                response.A?.error || response.error,
                () => console.log('user ok')
              );
            }
          }

          //console.log('rest2Api.vitec_participant_obj', response);
          // this.dummy = response;
          this.get_viewing_participants();
        }
      }
      this.userDialog = false;

      this.resetParticipantForm();
    },
    upd_participants() {
      this.get_viewing_participants();

      this.get_participated();
    },

    // run_lookup() {
    //   // console.log('kör run lookup');
    //   // om inget mobilnr är ifyllt gör uppslag på mailadress
    //   if (
    //     this.participant.cellPhone == null ||
    //     this.participant.cellPhone == ''
    //   )
    //     this.lookup_contact();
    // },

    lookup_contact() {
      // var contactId = '';

      if (
        !this.is_valid_phone_number(this.participant.cellPhone) &&
        this.participant.email == null
      ) {
        this.alertDialogNoCancel(
          this.getLang('v6_viewing', 'lbl_invalidnumber'),
          this.getLang('v6_viewing', 'txt_invalidnumber'),

          () => console.log('user ok')
        );
        return;
      }

      var customerId = get(
        this.$store.state.infopingstore,
        'selected_viewing.item.customerId',
        null
      );

      // CustomerId MUST EXISTS
      if (customerId != '') {
        // NY DELTAGARE
        //  if (this.contactId == '') {
        var obj = Q.vitec_contact_get_obj();
        obj.Q.customerId = customerId;
        obj.Q.cellPhone = this.participant.cellPhone;
        obj.Q.emailAddress = this.participant.email;
        obj.Q.batch_key = get(
          this.$store.state.infopingstore,
          'selected_viewing.item.batch_key',
          null
        );

        obj = Q.clean_null_obj(obj);
        rest2Api.vitec_contact_obj(obj).then((response) => {
          if (response) {
            //om man gjort ett uppslag och gör ett till måste informationen tömmas
            if (this.participant.vitec_contact_id) {
              this.participant.first_name = null;
              this.participant.last_name = null;
              this.participant.note = null;
              this.participant.valuation = '0';
              // this.participant.vitec_contact_id = null;
              this.participant.email = null;
            }
            //  console.log('kör lookup contact svar', response);

            this.participant.vitec_contact_id = get(
              response,
              'contactId',
              null
            );

            // HIITAT DELTAGARE I DB fyller bara i uppgifter som saknas
            //annars tömdes formuläret om man skrev namn telnr och sparade utan att lämna telnrfältet
            if (this.participant.vitec_contact_id != null) {
              //CELLPHONE
              //if (dia_cellPhone == null && res_cellPhone != null)
              if (!this.participant.cellPhone)
                this.participant.cellPhone = response.cellPhone;

              // EMAIL
              //if (dia_emailaddress == null && res_emailaddress != null)
              if (!this.participant.email)
                this.participant.email = response.emailAddress;

              // FIRST NAME
              //if (dia_firstName == null && res_firstName != null)
              if (!this.participant.first_name)
                this.participant.first_name = response.firstName;

              // LAST NAME
              //if (dia_lastName == null && res_lastName != null)
              if (!this.participant.last_name)
                this.participant.last_name = response.lastName;

              // CONTACT ID
              if (!this.participant.vitec_contact_id)
                this.participant.vitec_contact_id = response.contactId;

              //     this.chk_mobile('HIITAT DELTAGARE I DB');
            }
            // DELTAGAREN EXISTERAR INTE
            // SKAPAR DELTAGARE I VITEC OM MOBILEN ÄR I FYLLD
            else if (this.participant.cellPhone != null) {
              obj = Q.vitec_contact_upd_obj();
              obj.Q.customerId = customerId;
              obj.Q.cellPhone = this.participant.cellPhone;
              //  obj.Q.emailAddress = this.participant.email;
              // obj.Q.firstName = this.participant.first_name;
              // obj.Q.lastName = this.participant.last_name;

              obj = Q.clean_null_obj(obj);

              rest2Api.vitec_contact_upd(obj).then((response) => {
                if (response) {
                  this.participant.vitec_contact_id = get(
                    response,
                    'contactId',
                    null
                  );

                  //om svaret inte innehåller namn mm så behåll det användaren skriit
                  if (response.firstName)
                    this.participant.first_name = response.firstName;
                  if (response.lastName)
                    this.participant.last_name = response.lastName;
                  if (response.emailAddress)
                    this.participant.email = response.emailAddress;
                  if (response.cellPhone)
                    this.participant.cellPhone = response.cellPhone;

                  // this.theTesttest = this.empty_if_null('x');
                  // this.theTest = this.empty_if_null('');

                  //      this.chk_mobile('SKAPAR DELTAGARE');
                  //   this.chk_save_disabled();
                  // }
                }
              });
            }
          }
        });
        //  }
      }
      //  this.chk_mobile('end');

      //  this.chk_save_disabled();
    },
  },
};
</script>
<style scoped>
.dia {
  width: 800px;
}
</style>
