
import infopingstore from '../store/infopingstore'; // Adjust the path to your store file
import get from "lodash/get";
export function get_header()
{   
    //console.log(infopingstore)
    let answer = {
        'Content-Type': 'application/json',
        'Source-Id': get(infopingstore.state,'source_id',''),
        'Source-Name': get(infopingstore.state,'source_name',''),
        'Source-Number': get(infopingstore.state,'verify_token.phone_number','')
      };
      return answer;
}