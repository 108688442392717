//----------------------------------------------
// VISNINGSASSISTENTEN
//----------------------------------------------
export const SOURCE_ID = 'SOURCE_ID';
export const SOURCE_NAME = 'SOURCE_NAME';
export const SOURCE_NUMBER = 'SOURCE_NUMBER';
//----------------------------------------------
// VITEC
//----------------------------------------------
export const LIST_VIEWINGS = 'LIST_VIEWINGS';
export const LIST_ALL_VIEWINGS = 'LIST_ALL_VIEWINGS';
export const SELECTED_VIEWING = 'SELECTED_VIEWING';
export const VIEWING_PARTICIPANTS = 'VIEWING_PARTICIPANTS';
export const VIEWING_BROKER = 'VIEWING_BROKER';
export const VIEWING_STARTED = 'VIEWING_STARTED';
//export const OFFICES = 'OFFICES';
export const BROKERS = 'BROKERS';
export const HEADER_NAME = 'HEADER_NAME';
export const VIEWING_HOST = 'VIEWING_HOST';
export const PERMISSIONS = 'PERMISSIONS';


//----------------------------------------------
// INFOPING
//----------------------------------------------
export const VERIFY_TOKEN = 'VERIFY_TOKEN';
export const SESSION = 'SESSION';
export const ORG_BRANDING = 'ORG_BRANDING';
export const BATCH = 'BATCH';
export const ACCESS = 'ACCESS';
export const USER_TYPE = 'USER_TYPE';


//----------------------------------------------
// LAYOUT
//----------------------------------------------

export const SHOW_ALERT='SHOW_ALERT';
export const LANG_FILTER='LANG_FILTER';



export const VITEC = 'VITEC';