<template>
  <div :class="{ fullScreen: $vuetify.breakpoint.smAndUp }">
    <v-card class="justify-center" height="100vh" elevation="10">
      <!-- Kod för att avsluta visning   -->
      <v-dialog v-model="code" max-width="220px">
        <v-card autocomplete="off">
          <div style="padding:15px">
            <v-label>{{ this.getLang('v6_viewing', 'lbl_code') }}</v-label>
            <!--          class="txt-center"-->
            <v-text-field
              style="padding-top:25px"
              v-model="closeItem.pwd"
              background-color="var(--border-container-bg-color)"
              class="txt-center"
              :type="'password'"
              @keydown="keypressedCode"
              autofocus
              :label="
                this.getLang('v6_viewing', 'lbl_codeheader').toUpperCase()
              "
              clearable
              data-lpignore="true"
            ></v-text-field>

            <v-btn class="btn-close" @click="finish" text>
              {{ this.getLang('v6_viewing', 'lbl_codeclose') }}</v-btn
            >
          </div>
        </v-card>
      </v-dialog>

      <!-- felmeddelande om spekulanten inte sparas    -->
      <v-dialog v-model="code2" max-width="220px">
        <v-card autocomplete="off">
          <div style="padding:15px">
            <div v-if="no_network" style="margin-bottom:15px">
              <p class="font-weight-bold">
                <v-label>
                  {{ this.getLang('v6_viewing', 'lbl_nonetwork') }}</v-label
                ><br />
              </p>
              <v-label style="padding-top:25px">{{
                this.getLang('v6_viewing', 'txt_notsaved')
              }}</v-label>

              <br />
            </div>
            <div v-if="not_saved" style="margin-bottom:15px">
              <p class="font-weight-bold">
                <v-label>{{
                  this.getLang('v6_viewing', 'txt_notsaved')
                }}</v-label>
              </p>
              <br />
              <div style="font-size: 0.3em">
                <v-label>{{
                  this.getLang('v6_viewing', 'txt_notsavedbroker')
                }}</v-label>
              </div>
              <br />
            </div>

            <v-text-field
              style="padding-top:25px"
              v-model="closeItem.pwd"
              class="txt-center"
              background-color="var(--border-container-bg-color)"
              :type="'password'"
              @keydown="keypressedCode"
              autofocus
              :label="
                this.getLang('v6_viewing', 'lbl_codeheader').toUpperCase()
              "
              clearable
              data-lpignore="true"
            ></v-text-field>
            <v-btn class="btn-close" @click="finish" text>
              {{ this.getLang('v6_viewing', 'lbl_ok') }}</v-btn
            >
          </div>
        </v-card>
      </v-dialog>

      <!-- registrering     :fullscreen="$vuetify.breakpoint.xsOnly" -->
      <v-dialog
        max-width="1000px"
        v-model="register"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        height="100vh"
        persistent
        overlay-color="transparent"
      >
        <v-card class="noSwim" flat autocomplete="off">
          <!-- height="90vh"-->
          <v-btn
            class="btn-finish"
            min-width="1"
            text
            @click="close"
            style="z-index: 9999; background-color: transparent;"
            icon
            small
          >
            <v-icon small>mdi-window-close</v-icon>
          </v-btn>
          <!-- <v-row>-->
          <v-row style="justify-content: center;">
            <div v-if="customLogo()">
              <img class="logo" />
            </div>
            <div v-else>
              <img
                :class="get_logo_url()"
                style="max-width: 300px; padding-top: 10px"
              />
            </div>
          </v-row>
          <v-col cols="12">
            <v-row justify="center">
              <label style="font-size: 0.9em">
                {{ viewing_txt() }}
              </label>
            </v-row>
          </v-col>
          <!--   </v-row>-->
          <v-card-text align="center">
            <!--   style="margin-bottom: 0px; margin-top: 20px"-->
            <v-container class="borderContainer">
              <v-row class="center">
                <label style="font-size: 1.3em">
                  {{ this.getLang('v6_viewing', 'lbl_fillin') }}
                </label>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-text style="padding-bottom: 5px">
            <v-container class="borderContainer">
              <v-row>
                <v-col cols="12" xl="5" lg="5" md="5" sm="5">
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    class="inputClass"
                    v-model="selfRegistration.cellPhone"
                    :placeholder="labelPhone()"
                    v-on:blur="lookup_contact()"
                    data-lpignore="true"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" xl="7" lg="7" md="7" sm="7">
                  <v-text-field
                    outlined
                    hide-details
                    dense
                    class="inputClass"
                    v-model="selfRegistration.email"
                    :placeholder="labelEmail()"
                    data-lpignore="true"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" xl="5" lg="5" md="5" sm="5">
                  <v-text-field
                    outlined
                    hide-details
                    dense
                    v-model="selfRegistration.first_name"
                    class="inputClass"
                    :placeholder="this.getLang('v6_viewing', 'lbl_firstname')"
                    data-lpignore="true"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xl="7" lg="7" md="7" sm="7">
                  <v-text-field
                    outlined
                    hide-details
                    dense
                    class="inputClass"
                    v-model="selfRegistration.last_name"
                    :placeholder="this.getLang('v6_viewing', 'lbl_lastname')"
                    data-lpignore="true"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-textarea
                    wrap
                    outlined
                    hide-details
                    class="inputClass"
                    v-model="selfRegistration.note"
                    :placeholder="this.getLang('v6_viewing', 'lbl_note')"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-row class="pd-zero">
                <v-col cols="12" class="pd-zero">
                  <v-row align="center" class="pd-zero">
                    <v-checkbox
                      :label="this.getLang('v6_viewing', 'lbl_consent')"
                      false-value="0"
                      true-value="1"
                      :value="1"
                      v-model="approve"
                    >
                    </v-checkbox>
                    <v-btn small text :href="policy_link" target="_blank">{{
                      this.getLang('v6_viewing', 'lbl_policy')
                    }}</v-btn>
                  </v-row>
                </v-col>
              </v-row>
              <!-- <v-row>
                <v-col cols="4">
                  <v-checkbox
                    label="LÅNELÖFTE"
                    false-value="0"
                    true-value="1"
                    v-model="dialogItem.c[ix_wish_lone_promise]"
                  ></v-checkbox>
                </v-col>
                </v-row> -->
              <v-row class="pd-zero">
                <v-col cols="12" class="pd-zero">
                  <v-checkbox
                    :label="this.getLang('v6_viewing', 'lbl_freevaluation')"
                    false-value="0"
                    true-value="1"
                    v-model="selfRegistration.valuation"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-label v-if="orgKey == '2283'"
                >*obligatoriska uppgifter</v-label
              >
              <!-- <v-row class="pd-zero"> -->
              <!--  <v-col cols="0" class="pd-zero" hidden>
                <v-text-field
                  class="hiddenArea"
                  v-model="theTest"
                  :disabled="is_disable"
                ></v-text-field>
              </v-col>-->
              <!-- </v-row> -->
            </v-container>
          </v-card-text>

          <v-card-actions class="justify-center">
            <v-btn
              :disabled="save_disabled"
              color="var(--menu-tabs-bg-color)"
              style="color:var(--menu-tabs-text-color)"
              @click="save"
              >{{ this.getLang('v6_viewing', 'lbl_savedata') }}
            </v-btn>
            <v-btn text @click="clean_form">{{
              this.getLang('v6_viewing', 'lbl_clear')
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import componentsMixin from './componentsMixin.js';
import Q from '@/questions.js';
import rest2Api from '@/api/rest2Api.js';
import get from 'lodash/get';
export default {
  name: 'start_viewing',

  mixins: [componentsMixin],
  props: {},

  components: {},

  data() {
    return {
      selfRegistration: {
        phone_number: null,
        first_name: null,
        last_name: null,
        email: null,
        note: null,
        attended: '1',
        valuation: null,
        vitec_contact_id: null,
        cellPhone: null,
      },
      approve: '0',
      okToLeave: false,
      register: true,
      code: false,
      code2: false,
      not_saved: false,
      no_network: false,
      is_edit: false,
      // is_disable: true,
      last_mobile: '',
      icon_round: true,
      //  has_mobile: false,

      customer: null,
      // save_disabled: true,
      policy_link:
        'https://www.vitecsoftware.com/sv/produktomraden/maklare/personuppgiftspolicy-maklare/',

      // emptyItem: {
      //   user_name: '',
      //   phone_number: '',
      //   test: '',
      //   c: this.get_empty_array(20),
      // },
      // registerItem: {
      //   user_name: '',
      //   phone_number: '',
      //   test: '',
      //   c: this.get_empty_array(20),
      // },
      closeItem: {
        pwd: '',
      },
    };
  },

  watch: {},
  computed: {
    save_disabled() {
      if (this.is_valid_phone_number(this.selfRegistration.cellPhone))
        return false;
      else return true;
    },
    orgKey() {
      return get(
        this.$store.state.infopingstore,
        'selected_viewing.item.org_key',
        null
      );
    },

    batch_key() {
      return get(
        this.$store.state.infopingstore.selected_viewing,
        'item.batch_key',
        null
      );
    },
  },
  beforeMount() {
    this.set_policy_link();
    var icon_round = get(
      this.$store.state.infopingstore,
      'org_branding.header_icon',
      ''
    );

    if (icon_round == '' || icon_round == 'round') {
      this.icon_round = true;
    } else this.icon_round = false;
  },
  async mounted() {
    //gick inte att ha detta i selected viewing pga timingproblem.
    //funkar inte att watcha storen, kollar här om det är en reload (skulle helst göra det när visningarna finns i storen)
    if (!this.batch_key) await this.setDefaultViewing(this.$route.params.id);
  },
  created() {
    // this.registerItem = JSON.parse(JSON.stringify(this.emptyItem));
    // this.registerItem.c[this.ix_attended] = "1";
    this.clean_form();
  },
  //Hindra användaren att lämna sidan
  beforeRouteLeave(to, from, next) {
    if (this.okToLeave) {
      next();
    } else {
      next(false);
    }
  },
  methods: {
    labelEmail() {
      var label = this.getLang('v6_viewing', 'lbl_email');
      if (this.orgKey == '2283') label = label + '*';

      return label;
    },

    labelPhone() {
      var label = this.getLang('v6_viewing', 'lbl_phone');
      if (this.orgKey == '2283') label = label + '*';

      return label;
    },

    async set_policy_link() {
      var customerId = get(
        this.$store.state.infopingstore,
        'selected_viewing.item.customerId',
        ''
      );
      if (customerId != '') {
        await rest2Api.vitec_customer_get(customerId).then((res) => {
          var items = get(res, 'items', []);

          if (items.length > 0) {
            items.forEach((item) => {
              if (item.name == 'policy_link') {
                var policy_link = get(item, 'value', '');
                if (policy_link != '') this.policy_link = policy_link;
              }
            });
          }
        });
      }
    },

    keypressedCode(keyEvt) {
      if (keyEvt.key == 'Enter') this.finish();
    },
    viewing_txt() {
      return get(
        this.$store.state.infopingstore.selected_viewing,
        'item.batch_name',
        null
      );
    },
    click_close_viewing() {
      this.$store.dispatch('infopingstore/fetch_viewing_started', false);
      //  this.$router.push({
      //       path: 'Main',
      //       query: { page: 'SelectedViewing' },
      //     });
    },
    clean_form() {
      this.last_mobile = '';
      this.approve = '0';
      this.selfRegistration = {};
      this.selfRegistration.attended = '1';
    },
    close() {
      //this.dialog = false;
      this.code = true;
    },
    finish() {
      var phone_number = get(
        this.$store.state.infopingstore,
        'verify_token.phone_number',
        ''
      );
      var code = phone_number.substr(phone_number.length - 4);

      if (this.closeItem.pwd == code) {
        this.register = false;
        this.code = false;
        this.code2 = false;
        this.no_network = false;
        this.not_saved = false;
        this.$store.dispatch('infopingstore/fetch_viewing_started', false);
        this.okToLeave = true;
        this.$router.push({
          path: '/SelectedViewing/' + this.$route.params.id + '/Participants',
        });
      }
    },
    lookup_contact() {
      // var contactId = '';
      if (!this.is_valid_phone_number(this.selfRegistration.cellPhone)) {
        this.alertDialogNoCancel(
          this.getLang('v6_viewing', 'lbl_invalidnumber'),
          this.getLang('v6_viewing', 'txt_invalidnumber'),

          () => console.log('user ok')
        );
        return;
      }

      var customerId = get(
        this.$store.state.infopingstore,
        'selected_viewing.item.customerId',
        null
      );

      // CustomerId MUST EXISTS
      if (customerId != '') {
        // NY DELTAGARE
        //   if (contactId == '') {
        var obj = Q.vitec_contact_get_obj();
        obj.Q.customerId = customerId;
        obj.Q.cellPhone = this.selfRegistration.cellPhone;
        obj.Q.emailAddress = this.selfRegistration.email;
        obj.Q.batch_key = this.batch_key;
        obj = Q.clean_null_obj(obj);
        //console.log('dddd', JSON.stringify(obj));
        rest2Api.vitec_contact_obj(obj).then((response) => {
          if (response) {
            if (this.selfRegistration.vitec_contact_id) {
              this.selfRegistration.first_name = null;
              this.selfRegistration.last_name = null;
              this.selfRegistration.email = null;
              this.selfRegistration.note = null;
              this.selfRegistration.valuation = '0';
            }

            this.selfRegistration.vitec_contact_id = get(
              response,
              'contactId',
              null
            );

            // HIITAT DELTAGARE I DB
            if (this.selfRegistration.vitec_contact_id != null) {
              //CELLPHONE
              if (!this.selfRegistration.cellPhone)
                this.selfRegistration.cellPhone = response.cellPhone;

              // EMAIL
              if (!this.selfRegistration.email)
                this.selfRegistration.email = response.emailAddress;

              // FIRST NAME
              if (!this.selfRegistration.first_name)
                this.selfRegistration.first_name = response.firstName;

              // LAST NAME
              if (!this.selfRegistration.last_name)
                this.selfRegistration.last_name = response.lastName;

              // CONTACT ID
              //görs uppe

              //   this.is_disable = false;
              // this.theTest = this.empty_if_null('x');
              // this.theTest = this.empty_if_null('');
              // this.chk_save_disabled();

              //   this.is_disable = true;
              this.$forceUpdate();
              //    this.chk_mobile('HIITAT DELTAGARE I DB');
            }
            // DELTAGAREN EXISTERAR INTE
            // SKAPAR DELTAGARE I VITEC OM MOBILEN ÄR I FYLLD
            else if (this.selfRegistration.cellPhone != null) {
              obj = Q.vitec_contact_upd_obj();
              obj.Q.customerId = customerId;
              obj.Q.cellPhone = this.selfRegistration.cellPhone;
              // obj.Q.emailAddress = this.selfRegistration.email;
              //   obj.Q.firstName = this.selfRegistration.first_name;
              //  obj.Q.lastName = this.selfRegistration.last_name;

              obj = Q.clean_null_obj(obj);

              //console.log('lookup_phone_number', 'create', JSON.stringify(obj));
              rest2Api.vitec_contact_upd(obj).then((response) => {
                if (response) {
                  this.selfRegistration.vitec_contact_id = get(
                    response,
                    'contactId',
                    null
                  ); //response.contactId;

                  if (response.firstName)
                    this.selfRegistration.first_name = response.firstName;
                  if (response.lastName)
                    this.selfRegistration.last_name = response.lastName;
                  if (response.emailAddress)
                    this.selfRegistration.email = response.emailAddress;
                  if (response.cellPhone)
                    this.selfRegistration.cellPhone = response.cellPhone;
                }
              });
            }
          }
        });
        //  }
      }
      //  this.chk_mobile('end');
      // this.chk_save_disabled();
    },
    async save() {
      //  console.log(' save registered item', this.selfRegistration);
      if (!this.is_valid_phone_number(this.selfRegistration.cellPhone)) {
        this.alertDialogNoCancel(
          this.getLang('v6_viewing', 'lbl_invalidnumber'),
          this.getLang('v6_viewing', 'txt_invalidnumber'),

          () => console.log('user ok')
        );
        return;
      }

      if (this.orgKey == '2283' && !this.selfRegistration.email.includes('@')) {
        this.alertDialogNoCancel(
          '',
          'Epostadress är obligatoriskt',

          () => console.log('user ok')
        );
        return;
      }

      if (this.approve == '0') {
        this.alertDialogNoCancel(
          '',
          'Du måste godkänna att dina uppgifter sparas.',

          () => console.log('user ok')
        );
        return;
      }

      if (this.selfRegistration.cellPhone != null) {
        var obj = Q.vitec_participant_obj();

        obj.Q.contactId = this.selfRegistration.vitec_contact_id;
        obj.Q.cellPhone = this.selfRegistration.cellPhone;
        obj.Q.emailAddress = this.selfRegistration.email;
        obj.Q.lastName = this.selfRegistration.last_name;
        obj.Q.firstName = this.selfRegistration.first_name;
        obj.Q.attended = this.selfRegistration.attended;
        // obj.Q.wish_lone_promise = this.participantValuation
        obj.Q.valuation = this.selfRegistration.valuation;
        obj.Q.note = this.selfRegistration.note;
        // obj.Q.batch_gen_items_key = this.batch_gen_items_key;
        obj.Q.customerId = get(
          this.$store.state.infopingstore,
          'selected_viewing.item.customerId',
          null
        );

        obj.Q.org_key = get(
          this.$store.state.infopingstore,
          'selected_viewing.item.org_key',
          null
        );

        obj.Q.batch_key = this.batch_key;

        obj = Q.clean_null_obj(obj);
        //ev timingproblem där obj tömdes
        var stringObj = JSON.stringify(obj);

        if (obj.Q.batch_key == null || obj.Q.batch_key == '') {
          this.code2 = true;
          this.not_saved = true;
        }

        // UPDATE DB AND VITEC
        const response = await rest2Api.vitec_participant_obj_string(stringObj);

        if (response.error == 'Network Error') {
          this.code2 = true;
          this.no_network = true;
        } else if (response.A.status == '0' || response.A.status == 0) {
          this.code2 = true;
          this.not_saved = true;
        } else if (response.A.status == '1' || response.A.status == 1) {
          this.alertDialogNoCancel(
            'Tack',
            'Dina uppgifter har registrerats',

            () => console.log('user ok')
          );
        }
        setTimeout(() => {
          this.closeDialog();
        }, 3000);
        // console.log('rest2Api.vitec_participant_obj', response);

        this.clean_form();
      }
    },

    closeDialog() {
      // console.log('closed');
      this.$store.dispatch('infopingstore/showAlert', {
        show: false,
      });
    },
    alertDialogNoCancel(headerText, bodyText, okFunc) {
      this.$store.dispatch('infopingstore/showAlert', {
        show: true,
        headerText: headerText,
        bodyText: bodyText,
        showCancelButton: false,
        confirmAction: () => {
          okFunc();
        },
      });
    },
  },
};
</script>
<style lang="scss">
.fullScreen {
  display: flex;
  justify-content: center;
  background-color: var(--self-registration-bg);
  background-image: url(~@/assets/infoping_watermark.png);

  background-position: center 10px;
  // max-width: 600px;
  //min-height: 812px;
  min-height: 100%;
  width: 100%;
  font-size: 14px;
  background-size: cover;
}

.v-card-1 {
  min-height: '1024px';
  max-width: '200px';
  // min-height="1024";
  //   max-width="200";
}
.v-text-field__details {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
// .v-checkbox >>> label {
//   font-size: 12px;
// }

.v-input--checkbox .v-label {
  font-size: 14px;
}

.noSwim {
  overflow-x: hidden !important;
}

//funkar inte att hämta från main.scss i roten
.VitecPrintLogo {
  content: url('../../public/vitec_banner.png'); // url('https://infoping.net/img/logo/demomakl_transp.png');
  display: inline-block;
  max-width: 250px;
  max-height: 80px;
}
.TreEtagePrintLogo {
  content: url('https://infoping.net/rest2/ask/Q/logo/group/94A2AF63-AFC1-4D3D-9A93-365566E52C1F/s/');
  display: inline-block;
  max-width: 250px;
  max-height: 150px;
}
.AhlmPrintLogo {
  content: url('https://infoping.net/rest2/ask/Q/logo/group/924FC536-A772-4830-8DEA-46910361EC6B/');
  display: inline-block;
  max-width: 250px;
  max-height: 150px;
}
.BoportenPrintLogo {
  content: url('https://infoping.net/rest2/ask/Q/logo/group/C8F2D04F-A895-42CE-9BFD-563ED2333AD8/s/00ED39B7-3362-4EEF-B3B5-460CAE538149/');
  display: inline-block;
  max-width: 250px;
  max-height: 80px;
}

.HHPrintLogo {
  content: url('../../public/images/husmanhagberg/husman.png');
  display: inline-block;
  max-width: 250px;
  max-height: 80px;
}

.MCentrumPrintLogo {
  content: url('https://infoping.net/rest2/ask/Q/logo/group/E181E27F-FED6-4ECA-B707-67D2B650DFEB/s/');
  display: inline-block;
  max-width: 250px;
  max-height: 120px;
}

.MRPrintLogo {
  content: url('https://infoping.net/rest2/ask/Q/logo/group/21D5A75D-6ABC-4972-B641-2DE158BB6CFC/s/');
  display: inline-block;
  max-width: 250px;
  max-height: 80px;
}
.RamaPrintLogo {
  content: url('https://infoping.net/rest2/ask/Q/logo/group/4EAFBDD9-BC85-4D40-A8B3-22F24CEEB5CD/s/');
  display: inline-block;
  max-width: 250px;
  max-height: 120px;
}
.OlandPrintLogo {
  content: url('https://infoping.net/rest2/ask/Q/logo/group/F3D1E3E8-2BBC-4FCA-9C59-A70A1FABBFB1/s/');
  display: inline-block;
  max-width: 250px;
  max-height: 80px;
}
</style>
