<template>
  <div :class="{ desktopView: $vuetify.breakpoint.mdAndUp }">
    <v-container style="padding: 0px;">
      <br />

      <v-row
        no-gutters
        class="topStyle"
        :style="
          $vuetify.breakpoint.xs
            ? 'margin: var(--page-margins-mobile); padding-top:10px;'
            : 'padding-top:10px'
        "
      >
        <v-select
          dense
          :items="items"
          item-text="name"
          item-value="value"
          solo
          flat
          class="selectClass"
          hide-details
          v-model="selectedRadio"
        ></v-select>
      </v-row>
      <v-row
        no-gutters
        class="topStyle"
        dense
        :style="
          $vuetify.breakpoint.xs ? 'margin: var(--page-margins-mobile)' : ''
        "
      >
        <v-text-field
          class="searchClass"
          solo
          dense
          flat
          v-model="searchValue"
          prepend-icon="mdi-magnify"
          label="Sök"
          single-line
          hide-details
          clearable
          @click:clear="clearSearch()"
        ></v-text-field>
      </v-row>
      <!--    v-on:keyup="onSearch"-->
      <v-list
        two-line
        :style="
          $vuetify.breakpoint.xs ? 'margin: var(--page-margins-mobile)' : ''
        "
      >
        <v-list-item-group>
          <!--v-model="selectedIndex"    class="borderContainer"  :src="item.logo"  v-for="(item, index)-->
          <template v-for="item in viewings">
            <v-list-item
              style="padding: 0px"
              class="borderContainer"
              :key="item.batch_key"
              @click="rowClick(item)"
            >
              <template>
                <v-col class="ma-0 pa-0" cols="4" xl="2" lg="2" md="2" sm="2">
                  <!--  <v-list-item-avatar
                  style="margin: 0px"
                  tile
                  size="100"
                  class="rounded"
                >-->
                  <!--   :src="imgSource()"-->
                  <v-img
                    class="estateImage"
                    :aspect-ratio="1 / 1"
                    :src="imgSource(item)"
                    lazy-src="https://infoping.net/img/estate/no_image.png"
                    ><template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row> </template
                  ></v-img>
                </v-col>
                <v-col
                  class="ma-0 pa-0"
                  cols="8"
                  xl="10"
                  lg="10"
                  md="10"
                  sm="10"
                >
                  <!--  </v-list-item-avatar>       v-text="item.batch_name.substr(24)"-->
                  <v-list-item-content>
                    <v-list-item-subtitle
                      style="font-weight: bold; padding-left: 4px"
                      v-text="item.streetAddress"
                    ></v-list-item-subtitle>

                    <v-list-item-subtitle
                      style="padding-left: 4px"
                      v-text="item.startsAt"
                    ></v-list-item-subtitle>

                    <v-list-item-subtitle
                      style="margin-top: 5px;font-size:12px; padding-left: 4px"
                    >
                      <label> {{ getLang('v6_viewing', 'lbl_booked') }}:</label>
                      <label style="color: var(--accent-color)">
                        {{ item.prebooked }}</label
                      >
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-col>
              </template>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
      <span id="center">
        <Loader v-if="loading" />
      </span>
    </v-container>
  </div>
</template>

<script>
//import Header from '../components/Header.vue'

import componentsMixin from './componentsMixin.js';
import cloneDeep from 'lodash/cloneDeep';
import Loader from '../components/Loader.vue';
// import rest2Api from "@/api/rest2Api.js";
import Q from '@/questions.js';
import get from 'lodash/get';
export default {
  name: 'Viewings',
  mixins: [componentsMixin],
  props: {},
  components: { Loader },
  watch: {
    selectedRadio() {
      this.getAllViewings();
    },
  },
  data() {
    return {
      loading: false,
      searchValue: '',
      //  sortBy: 'item.startsAt',
      //  sortDesc: false,
      nowDate: new Date().toISOString().slice(0, 10),
      //nowTime: new Date().toISOString().slice(11, 16),
      selectedRadio: 'future',
      items: [
        {
          name: this.getLang('v6_viewing', 'lbl_upcoming').toUpperCase(),
          value: 'future',
        },
        {
          name: this.getLang('v6_viewing', 'lbl_previous').toUpperCase(),
          value: 'past',
        },
        {
          name: this.getLang('v6_viewing', 'lbl_all').toUpperCase(),
          value: 'all',
        },
      ],
      // headers: [
      //   {
      //     text: this.getLang('v6_viewing', 'lbl_showings'),
      //     value: 'batch_name',
      //   },
      //  ],
      // darkMode: false,
    };
  },
  computed: {
    viewings() {
      //return this.$store.state.infopingstore.list_viewings;

      if (this.selectedRadio == 'all') {
        var av = this.$store.state.infopingstore.list_all_viewings;
        var allViewings = cloneDeep(av);
        allViewings.sort((a, b) =>
          a.startsAt > b.startsAt ? 1 : b.startsAt > a.startsAt ? -1 : 0
        );

        var filtered = allViewings;
        if (this.searchValue) {
          filtered = allViewings.filter((item) => {
            return (
              item.batch_name
                .toLowerCase()
                .match(this.searchValue.toLowerCase()) ||
              item.startsAt.toLowerCase().match(this.searchValue.toLowerCase())
            );
          });
        }
        return filtered;
        // return allViewings;
      } else if (this.selectedRadio == 'past') {
        var pastViewings = [];
        //var nutid = this.nowDate + ' ' + this.nowTime;
        var allViewings2 = this.$store.state.infopingstore.list_all_viewings;
        allViewings2.forEach((element) => {
          if (element.startsAt < this.nowDate) pastViewings.push(element);
        });
        pastViewings.sort((a, b) =>
          a.startsAt > b.startsAt ? -1 : b.startsAt > a.startsAt ? 1 : 0
        );

        var filtered2 = pastViewings;

        if (this.searchValue) {
          filtered2 = pastViewings.filter((item) => {
            return (
              item.batch_name
                .toLowerCase()
                .match(this.searchValue.toLowerCase()) ||
              item.startsAt.toLowerCase().match(this.searchValue.toLowerCase())
            );
          });
        }
        return filtered2;
        //return pastViewings;
      } else {
        var futureViewings = this.$store.state.infopingstore.list_viewings;
        var filtered3 = futureViewings;

        if (this.searchValue) {
          filtered3 = futureViewings.filter((item) => {
            return (
              item.batch_name
                .toLowerCase()
                .match(this.searchValue.toLowerCase()) ||
              item.startsAt.toLowerCase().match(this.searchValue.toLowerCase())
            );
          });
        }

        if (filtered3 && filtered3.length == 0)
          return [
            {
              streetAddress: this.getLang('v6_viewing', 'lbl_noviewings'),
              estateId: 'Ingen bild', //ska inte översättas
            },
          ];
        else return filtered3;

        //  return this.$store.state.infopingstore.list_viewings;
      }
    },
  },
  beforeMount() {
    // nollar tidigare visning
    this.$store.dispatch('infopingstore/fetch_selected_viewing', []);
  },
  methods: {
    clearSearch() {
      this.searchValue = '';
    },

    async getAllViewings() {
      var viewings = get(
        this.$store.state.infopingstore,
        'list_all_viewings',
        []
      );
      if (viewings && viewings.length == 0) {
        this.loading = true;
        // GET VISNINGAR
        var session = get(this.$store.state.infopingstore, 'session', '');
        var obj = Q.vitec_batch_list();
        obj.Q.session = session;
        obj.Q.searchStr = '';
        obj = Q.clean_null_obj(obj);
        await this.$store.dispatch(
          'infopingstore/fetch_vitec_batch_list_all',
          obj
        );
        this.loading = false;
      }
    },

    imgSource(item) {
      //console.log('item', item);
      var estateID = item.estateId.toUpperCase();
      //console.log('estateid', estateID);
      if (estateID != 'INGEN BILD')
        var estate_image =
          'https://infoping.net/img/estate/' + estateID + '.jpg';
      else estate_image = 'https://infoping.net/img/estate/no_image.png';
      //console.log('path', estate_image);

      return estate_image;
    },

    rowClick(item) {
      //console.log('kommer den med item här ibland', item);
      this.viewing_start_end(item.batch_key, item.batch_name, 'start');

      // console.log(1); // la till item: här
      this.$store
        .dispatch('infopingstore/fetch_selected_viewing', { item: item })
        .then(() => {
          this.get_permissions();
        });

      this.$router.push({
        path: '/SelectedViewing/' + item.batch_key.toString() + '/Participants',
        //path: '/SelectedViewing',
        // query: { page: 'SelectedViewing' },
      });
    },
    get_permissions() {
      var org_key = get(
        this.$store.state.infopingstore,
        'selected_viewing.item.org_key',
        0
      );

      var customerId = get(
        this.$store.state.infopingstore,
        'selected_viewing.item.customerId',
        ''
      );
      var phone_number = get(
        this.$store.state.infopingstore,
        'verify_token.phone_number',
        ''
      );

      var obj = Q.vitec_permissions_obj();
      obj.org_key = org_key;
      obj.customerId = customerId;
      obj.phone_number = phone_number;

      obj = Q.clean_null_obj(obj);

      this.$store.dispatch('infopingstore/fetch_permissions', obj);
    },
  },
};
</script>
<style scoped>
.estateImage {
  border-radius: 15px;
  max-width: 100px;
  margin: 0px;
  padding: 0px;
}

.selectClass {
  max-width: 250px;
  margin: 0px;
  margin-top: -10px;

  font-size: 0.8em;
}
/* .searchClass {
  max-width: 250px;
  padding-left: 15px;
  margin-top: -10px;
}

.searchClassDark {
  max-width: 250px;
  padding-left: 15px;
  margin-top: -10px;
  margin-bottom: -10px;
  background-color: var(--secondary-bg-color);
} */

#center {
  width: 100%;

  align-items: center;
  display: flex;
}
</style>
