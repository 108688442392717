<template>
  <v-app>
    <div class="loginBackground">
      <!-- Vitec header -->
      <!-- <v-container fluid class="box" style="padding:0px">-->
      <v-container fluid style="padding:0px">
        <v-row no-gutters>
          <v-col cols="auto">
            <!-- vitec logo  <div>
              <img
                contain
                class="header-img"
                src="/vitec_logga.png"
                height="75px"
                width="75px"
              />
            </div> -->
          </v-col>
        </v-row>
      </v-container>
      <!-- ------------  -->
      <div>
        <v-dialog persistent v-model="isDialogOpen" width="400">
          <v-card color="white">
            <v-card-title class="headline" primary-title
              >Inloggning</v-card-title
            >

            <v-card-text>
              <v-form v-model="form1Valid">
                <div style="padding-top:40px">
                  <v-text-field
                    filled
                    v-model="phone_number"
                    label="Ange mobilnummer"
                    clearable
                    :disabled="showconfirm || loading"
                    @keydown="keypressedPhone"
                    autofocus
                  ></v-text-field>
                </div>
              </v-form>

              <div v-if="showconfirm">
                <label>Fyll i koden du fått på sms:</label>
                <br />
                <v-text-field
                  class="upperCaseInput"
                  filled
                  v-model="sms_code"
                  label="Valideringskod:"
                  placeholder="XX-1234"
                  :rules="[rules.required, rules.otp]"
                  clearable
                  autofocus
                  :disabled="loading"
                  @keydown="keypressedCode"
                ></v-text-field>
              </div>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn
                v-if="showconfirm"
                color="secondary"
                text
                @click="resetForm"
                >Avbryt</v-btn
              >

              <v-spacer></v-spacer>
              <v-btn
                text
                :loading="loading"
                @click="confirmButtonClick"
                :disabled="!form1Valid || loading"
                >{{ confirmButtonLabel }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </v-app>
</template>

<script>
import rest2Api from '@/api/rest2Api.js';

export default {
  name: 'login-page',
  bodyClass: 'login-page',

  data() {
    return {
      phone_number: '',
      sms_code: '',
      showconfirm: false,
      isDialogOpen: false,
      confirmButtonLabel: 'Nästa',
      form1Valid: true,
      loading: false,

      rules: {
        required: (value) => !!value || 'Obligatoriskt.',
        phone_number: (value) => {
          const pattern = /^[+]*[0-9]{2,2}[1-9]{1,1}[0-9]{8,8}$/;
          return pattern.test(value) || 'Ogiltigt telefonnummer';
        },
        otp: (value) => {
          const pattern = /^[A-Za-z]{2,2}[-][0-9]{4,4}$/;
          return pattern.test(value) || 'Ogiltigt format på koden: XX-1234';
        },
      },
    };
  },

  beforeMount() {
    this.$vuetify.theme.dark = false;
    this.$vuetify.theme.themes.light.primary = '#808080';
  },

  mounted() {
    window.setTimeout(() => (this.isDialogOpen = true), 1000);
  },
  watch: {
    sms_code() {
      if (this.sms_code && this.sms_code.length == 2) this.sms_code += '-';
    },
  },
  methods: {
    initLogin() {
      if (this.form1Valid) {
        //console.log("init login!: ", this.phone_number);
        rest2Api.initiateLogin(this.phone_number).then((res) => {
          if (res.status == '1') this.getCode();
          else {
            var errorMsg = res.error;
            if (errorMsg == 'could not initiate phone_number:')
              errorMsg = 'Mobilnummer saknas eller är felaktigt';
            this.$store
              .dispatch('infopingstore/showAlert', {
                show: true,
                bodyText: errorMsg,
              })
              .then(() => {
                this.resetForm();
              });
          }
        });
      }
    },

    getCode() {
      //console.log("get code!: ", this.phone_number);
      this.confirmButtonLabel = 'Verifiera kod';
      rest2Api.getCode(this.phone_number);
      this.showconfirm = true;
    },
    confirmCode() {
      //console.log("koden är: ", this.sms_code);
      //skicka till api och vänta på svar .then
      rest2Api.confirmCode(this.sms_code, this.phone_number).then((res) => {
        if (res.status == '1') {
          var path = process.env.NODE_ENV === 'production' ? '/' : '/';
          //  window.location.href = path + '?s=' + res.session;
          //om cookies inte tillåts lägg med sessionen i browsern
          if (navigator.cookieEnabled != true) {
            window.location.href = path + '?s=' + res.session;
          } else {
            //  sätt sessionen i cookien ist för att skriva den i browsern

            this.$emit('loginSucceeded', res.session);
          }
        } else {
          var errorMsg = res.error;

          if (errorMsg == 'ERROR:login not initiated') {
            errorMsg = 'Fel kod';
          }
          if (errorMsg == 'ERROR:login timed out') {
            errorMsg = 'Inloggningen tog för lång tid';
          }
          this.$store
            .dispatch('infopingstore/showAlert', {
              show: true,
              bodyText: 'Något gick fel: ' + errorMsg, //res.error,
            })
            .then(() => {
              this.resetForm();
            });

          // popup slutar funka
          //this.resetForm();
        }
      });
    },
    keypressedPhone(keyEvt) {
      //internet explorer (edge) buggade när +46 lades till automatiskt
      //  if (keyEvt.key == "0" && this.phone_number == "") {

      // this.phone_number = "+46";
      // keyEvt.preventDefault();

      //} else if
      if (keyEvt.key == 'Enter') this.confirmButtonClick();
    },
    keypressedCode(keyEvt) {
      if (keyEvt.key == 'Enter') this.confirmButtonClick();
      if (keyEvt.key == '-') {
        keyEvt.preventDefault();
      }
      // if (this.sms_code && this.sms_code.length>7)
      //   keyEvt.preventDefault();
    },
    onDialogClosed() {
      this.isDialogOpen = true;
    },
    confirmButtonClick() {
      if (this.showconfirm == false) {
        this.showconfirm = true;
        this.isDialogOpen = true;
        this.initLogin();
      } else {
        if (this.sms_code != '') {
          this.loading = true;
          this.confirmCode();
        }
      }
    },
    resetForm() {
      this.isDialogOpen = true;
      this.showconfirm = false;
      this.phone_number = '';
      this.sms_code = '';
      this.loading = false;
      this.confirmButtonLabel = 'Nästa';
    },

    isMobile() {
      if (
        /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  components: {},
};
</script>

<style>
body,
html {
  height: 100%;
  width: 100%;
}

.upperCaseInput input {
  text-transform: uppercase;
}

.loginBackground {
  background-image: url('~@/assets/background_1920.jpg');

  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
</style>
