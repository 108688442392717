import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
// import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

// const opts = {};
const opts =
{
    theme: {
      options: {
        customProperties: true,
      },
    },
  };

export default new Vuetify(opts);
