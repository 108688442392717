import {
  LIST_VIEWINGS,
  LIST_ALL_VIEWINGS,
  SELECTED_VIEWING,
  VIEWING_PARTICIPANTS,
  VIEWING_BROKER,
  VERIFY_TOKEN,
  SESSION,
  ORG_BRANDING,
  VIEWING_STARTED,
  BROKERS,
  //OFFICES,
  HEADER_NAME,
  VIEWING_HOST,
  BATCH,
  PERMISSIONS,
  SHOW_ALERT, 
  ACCESS,
  USER_TYPE,
 LANG_FILTER,
 SOURCE_ID,
 SOURCE_NAME,
 SOURCE_NUMBER,
 VITEC,

} from './types.js';
// import { COOKIE_NAME_SESSION } from "@/constants.js";

import rest2Api from '@/api/rest2Api.js';
import get from 'lodash/get';
//import Q from '@/questions.js';
export default {
//----------------------------------------------
// CLEAR ALL
//----------------------------------------------
async clear_all({ commit }) {
  
  commit(SESSION, null);
  commit(LIST_VIEWINGS, []);
  commit(SELECTED_VIEWING, []);
  commit(VIEWING_PARTICIPANTS, []);
  commit(VIEWING_BROKER, []);
  commit(VERIFY_TOKEN, []);
  commit(ORG_BRANDING, []);
  //commit(OFFICES, []);
  commit(BROKERS, []);
  commit(VIEWING_STARTED, false);
  commit(HEADER_NAME, '');
  commit(BATCH, []);

  
  // if(this.$cookies.isKey(COOKIE_NAME_SESSION))
  // this.$cookies.remove(COOKIE_NAME_SESSION);
  
},

//----------------------------------------------
// VISNINGSASSISTENTEN
//----------------------------------------------
async fetch_source_id({ commit },obj) {


  if(obj)
  {
    commit(SOURCE_ID, obj);

  }
  }
  
,async fetch_source_name({ commit },obj) {


  if(obj)
  {

    commit(SOURCE_NAME, obj);

  }
  }
  
,async fetch_source_number({ commit },obj) {


  if(obj)
  {

    commit(SOURCE_NUMBER, obj);

  }
  }
  
,
//----------------------------------------------
// VITEC
//----------------------------------------------


  
async fetch_permissions({ commit },obj) {


  if(obj)
  {

    var response = await rest2Api.vitec_permissions(obj);


    commit(PERMISSIONS, response);
    return response;

  }
  else
    {
      commit(PERMISSIONS, []);
      return [];
    }
  }
  
,
  async fetch_vitec_batch_list({ commit }, obj) {

    var response = await rest2Api.vitec_batch_list(obj);
    if (response.status == '1') 
    {
      commit(LIST_VIEWINGS, response.item);
      return response.item;
    }
    else 
    {
      commit(LIST_VIEWINGS, []);
      return [];
    }
   
    
  },
  async fetch_vitec_batch_list_all({ commit }, obj) {
    obj.Q.searchStr = '<<top200>>';
   // obj.Q.searchStr = '<<all>>';

    var response = await rest2Api.vitec_batch_list(obj);

    if (response.status == '1') 
    {
      commit(LIST_ALL_VIEWINGS, response.item);
      return response.item;
    }
    else 
    {
      commit(LIST_ALL_VIEWINGS, []);
      return [];
    }
    
  },



  async fetch_header_name({ commit }, name) {
    commit(HEADER_NAME, name);
    return name;
  },
  async fetch_selected_viewing({ commit}, obj) {
//console.log('fetch_selected_viewing', obj);
if (obj && obj.length == 0)
commit(VIEWING_BROKER, []);

    commit(SELECTED_VIEWING, obj);
    return obj;
  },

  async fetch_viewing_participants({ commit }, obj) {
    //console.log('fetch_viewing_participants','obj',obj)
    var response = await rest2Api.vitec_batch_obj(obj);

   // console.log('fetch_viewing_participants','response',response)
    
    if (response.status == '1') 
    {
      commit(VIEWING_PARTICIPANTS, response.items);
      commit(VIEWING_BROKER, response.broker);
      return "1";
    } 
    else 
    {
      commit(VIEWING_PARTICIPANTS, []);
      commit(VIEWING_BROKER, []);
      return get(
        response,
          'error',
          null
        );
    }
  },


  async clear_participants({ commit }) {

    commit(VIEWING_PARTICIPANTS, []);

  },

//----------------------------------------------
// INFOPING
//----------------------------------------------
async fetch_verify_token({ commit }, obj) {
  

  var response = await rest2Api.verify_token(obj);


  if (response.status == 1) 
    commit(VERIFY_TOKEN, response);
  else
    commit(VERIFY_TOKEN, []);

    return response;
} ,
async fetch_session({ commit }, session) {
 
  if (session.length>=36) 
    commit(SESSION, session);
  else
    commit(SESSION, '');

    return session;
} 
,
async fetch_viewing_started({ commit }, status) {
 
  if (status==true) 
    commit(VIEWING_STARTED, true);
  else
    commit(VIEWING_STARTED, false);

    return status;
} ,

async fetch_org_branding_session({ commit }, session) {
 
  var response = await rest2Api.org_branding_session(session);


  if (response.status == 1) 
    commit(ORG_BRANDING, response);
  else
    commit(ORG_BRANDING, []);

    return response;
} ,
async fetch_viewing_host({ commit }, obj) {
 // console.log('fetch_viewing_host?????',obj);
  var response = await rest2Api.vitec_viewing_host(obj);
//console.log('fetch_viewing_host svar',obj,response)
    commit(VIEWING_HOST, response);

    return response;
} ,

async fetch_batch({commit }, obj) {
//console.log('fetch_batch','obj',obj)
  var response = await rest2Api.batch_load(obj);

  
  if (response.status == '1') 
  {
   
   commit(BATCH, response.batch);
 return response.batch;
  }  else
  commit(BATCH, []);
 
},

async clear_batch({commit }) {

    commit(BATCH, []);
   
  },






async fetch_brokers ({commit, state}, obj) {

  
if (obj && obj.session == null)
{
  obj.session =  get(
    state,
      'session',
      []
    );
  }

  if(obj && obj.no_items)
  obj.no_items = null;


  var response = await rest2Api.check_access(obj);
//console.log('fetch broker answers', response.access[0].orgs);

  if (response.access)

  commit(BROKERS, response.access[0].orgs);


},



async check_access ({commit}, session ) {
  const obj = new Object();

 obj.session =  session
  var response = await rest2Api.check_access(obj);


  if (response.success == '1')
  {
  
   commit(USER_TYPE, response.user_type);
   if (response.access && response.access.length > 0)
  commit(ACCESS, response.access);
  else
  commit (ACCESS, []);
  }
  else {
  commit (ACCESS, []);
  commit(USER_TYPE, '') }
},


async clear_brokers({ commit }) {


  commit(BROKERS, []);

},

async save_vitec ({commit}, data) {

  //var response = await rest2Api.fetchVitecData(customerId);

commit (VITEC, data)

},



//----------------------------------------------
// LAYOUT
//----------------------------------------------
      // brings up a simple alert message box
      async showAlert({ commit }, alertData) {
   
        commit(SHOW_ALERT, alertData);
      },

      async fetch_lang_filter({ commit }, obj) {

        var ans = await rest2Api.lang_filter(obj);
      
          commit(LANG_FILTER, ans);

          return ans;
      }, 

}
