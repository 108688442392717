

import Viewings from './components/Viewings.vue';
import SelectedViewing from './components/SelectedViewing.vue';
import StartViewing from './components/StartViewing.vue';
import Participants from './components/Participants.vue';
import Answers from './components/Answers.vue';
import QR from './components/QR.vue';
import BrokerLogin from './pages/brokerLogin.vue';
import Reports from './pages/reports.vue';
import Vitec from './pages/vitec.vue';

export default {
  base: '',
  mode: 'history',
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '*',
      name: 'Default',
      component: Viewings,
    },
    {
      path: '/Viewings',
      name: 'Viewings',
      component: Viewings,
    },
    {
      path: '/SelectedViewing/:id',
      name: 'SelectedViewing',
      component: SelectedViewing,
      children: [
        {
          path: 'QR',
          name: 'QR',
          component: QR
        },
        {
          path: 'Participants',
          name: 'Participants',
          component: Participants
        },  
        {
          path: 'Answers',
          name: 'Answers',
          component: Answers,
        },  
        {
          path: 'StartViewing',
          name: 'StartViewing',
          component: StartViewing,
        },
     
      ]
    
    },
    {
      path: '/brokerlogin',
      name: 'BrokerLogin',
      component: BrokerLogin,
    },
    {
      path: '/reports',
      name: 'Reports',
      component: Reports,
    },
    {
      path: '/vitec',
      name: 'Vitec',
      component: Vitec,
    },
  ],    
};
