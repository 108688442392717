<template>
  <v-dialog
    v-model="show_viewing_host"
    overlay-color="transparent"
    max-width="400px"
  >
    <!--  overflow: hidden;-->
    <v-card style="padding: 10px 20px 10px 20px;">
      <v-row justify="end">
        <v-icon @click="close()">mdi-close</v-icon>
      </v-row>
      <v-row class="pd-zero" style="font-weight:bold; font-size:1.2em">
        VISNINGSVÄRDAR
      </v-row>
      <v-row
        v-if="selectedOffice"
        class="pd-zero"
        style="font-weight:bold; font-size:0.9em"
      >
        {{ this.selectedOffice.name }}
      </v-row>

      <v-row v-if="manage_assistants" class="pd-zero" align="center">
        <label style="font-weight:normal; font-size:0.8em; padding-top: 5px">
          Här kan du lägga till och ta bort visningsvärdar. Vill du knyta en
          värd till en specifik visning måste du först gå in på den aktuella
          visningen via menyn "visningar".</label
        >
      </v-row>

      <v-row v-else class="pd-zero" style="font-weight:normal; font-size:1.0em">
        {{ get_selected_viewing() }}

        <br />
        <br />
        <label style="font-size:0.8em; padding-top: 5px">
          Kryssa i de värdar som ska ha access till visningen.</label
        >
      </v-row>

      <br />

      <div v-if="showOffice && manage_assistants">
        <v-select
          outlined
          dense
          class="overflowOverride"
          :items="offices"
          item-text="name"
          item-value="customerId"
          return-object
          label="Välj kontor:"
          v-model="selectedOffice"
          v-if="showOffice"
        ></v-select>
      </div>
      <div v-else>
        <v-list subheader two-line style="width: 100%">
          <v-divider></v-divider>
          <!-- <v-subheader inset style="text-align: left; ">NAMN</v-subheader> -->
          <v-row class="pd-zero" style="font-weight:bold; ">
            <v-col cols="6">
              <label v-if="manage_assistants">
                Värdar
              </label>
              <label v-else>
                Tillgängliga värdar
              </label>
            </v-col>
            <v-col cols="6" style="text-align: right; ">
              <label v-if="manage_assistants">
                Radera
              </label>
              <label v-else>
                Värd på aktuell visning
              </label>
            </v-col>
          </v-row>

          <v-divider></v-divider>
          <div v-if="viewing_host && viewing_host.length == 0">
            <br />
            <label style="padding-left: 10px"> Inga värdar</label>
          </div>
          <v-list-item-group>
            <template v-for="item in viewing_host">
              <v-list-item :key="item.phone_number">
                <template>
                  <!--  <v-list-item-avatar>
                      <v-img :src="item.logo" height="30" width="30" style="margin:10px"></v-img>
                  </v-list-item-avatar>-->
                  <v-list-item-content>
                    <v-list-item-subtitle
                      style="font-weight: bold"
                      v-text="item.user_name"
                    ></v-list-item-subtitle>

                    <v-list-item-subtitle
                      v-text="item.phone_number"
                    ></v-list-item-subtitle>
                  </v-list-item-content>

                  <div v-if="manage_assistants">
                    <v-icon small @click="ask_del_viewing_host(item)">
                      mdi-delete
                    </v-icon>
                  </div>

                  <div v-else>
                    <v-checkbox
                      @click="access_change(item)"
                      dense
                      v-model="item.access"
                    ></v-checkbox>
                  </div>
                </template>
              </v-list-item>
              <!-- <v-divider
                v-if="index + 1 < participants_filtered.length"
                :key="index"
              ></v-divider> -->
            </template>
          </v-list-item-group>
        </v-list>
        <v-divider></v-divider>
        <br />
        <v-container style="padding: 20px; font-size: 0,9em">
          <v-row>
            LÄGG TILL NY VÄRD
          </v-row>

          <v-row>
            <label
              style="font-weight:normal; font-size:0.8em; padding-top: 5px"
            >
              Om önskad värd inte finns att välja i listan, fyll i namn och
              mobilnr och tryck på "lägg till".</label
            >
          </v-row>
          <v-row>
            <v-text-field v-model="user_name" label="Namn"> </v-text-field>
          </v-row>
          <v-row>
            <v-text-field v-model="phone_number" label="Mobilnummer">
            </v-text-field>
          </v-row>
        </v-container>
        <v-row class="justify-space-between">
          <v-btn text @click="add_viewing_host">lägg till</v-btn>

          <v-btn text @click="close()">STÄNG</v-btn>
        </v-row>
      </div>
      <!--  <v-btn text @click="manage_assistants = true">ta bort värdar</v-btn>-->
    </v-card>
  </v-dialog>
</template>
<script>
import get from 'lodash/get';

//import rest2Api from '@/api/rest2Api.js';

import componentsMixin from './componentsMixin.js';
import Q from '@/questions.js';

export default {
  name: 'ViewingAssistant',
  mixins: [componentsMixin],

  data() {
    return {
      phone_number: '',
      user_name: '',
      show_viewing_host: true,
      manage_assistants: false,

      headers: [
        {
          text: '',
          value: 'access',
          width: '10px',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Mobilnummer',
          value: 'phone_number',
          width: '180px',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Namn',
          value: 'user_name',
          align: 'left',
          bold: 'true',
        },
      ],

      selectedOffice: null,
      offices: [],
      showOffice: false,
    };
  },

  watch: {
    selectedOffice() {
      if (this.selectedOffice) this.showOffice = false;

      this.fetch_hosts();
    },
  },
  computed: {
    viewing_host() {
      var obj = get(this.$store.state.infopingstore, 'viewing_host', []);

      var clone = JSON.parse(JSON.stringify(obj));

      return clone.items;

      // return []
    },
  },
  beforeMount() {
    if (
      this.get_selected_viewing() == false ||
      this.get_selected_viewing() == null
    ) {
      this.manage_assistants = true;
    }
    this.getOffices();

    if (this.offices && this.offices.length > 1) this.showOffice = true;
  },
  methods: {
    getOffices() {
      var comp = this.$store.getters['infopingstore/broker_offices'](
        'viewing_assistant',
        get(this.$store.state.infopingstore, 'org_branding.org_key', '', [])
      );

      this.offices = comp;

      if (this.offices && this.offices.length == '1')
        this.selectedOffice = this.offices[0];

      //console.log('selected', this.selectedOffice);
      // return comp;
    },
    fetch_hosts() {
      console.log('feeetch');

      var obj = Q.vitec_viewing_host_obj();
      if (this.manage_assistants) {
        obj.Q.customerId = this.selectedOffice.customerId;
      } else {
        let customerId = get(
          this.$store.state.infopingstore,
          'selected_viewing.item.customerId',
          null
        );

        obj.Q.batch_key = get(
          this.$store.state.infopingstore,
          'selected_viewing.item.batch_key',
          null
        );
        obj.Q.customerId = customerId;
      }

      obj = Q.clean_null_obj(obj);
      this.$store.dispatch('infopingstore/fetch_viewing_host', obj);
    },

    get_selected_viewing() {
      var result = '';
      var batch_name = get(
        this.$store.state.infopingstore.selected_viewing,
        'item.batch_name',
        null
      );

      if (batch_name != null) {
        result = batch_name;
        this.selected_viewing = true;
      } else this.selected_viewing = false;

      return result;
    },

    ask_del_viewing_host(item) {
      console.log('ask delete', item);
      this.alertDialog(
        'Vill du ta bort värden?',

        item.user_name + ' tas då bort från samtliga visningar.',

        () => this.del_viewing_host(item),
        () => console.log('user cancel')
      );
    },
    del_viewing_host(item) {
      console.log(' delete', item);
      var obj = Q.vitec_viewing_host_obj();
      obj.Q.batch_key = get(
        this.$store.state.infopingstore,
        'selected_viewing.item.batch_key',
        null
      );

      if (this.manage_assistants) {
        obj.Q.customerId = this.selectedOffice.customerId;
      } else {
        obj.Q.customerId = get(
          this.$store.state.infopingstore,
          'selected_viewing.item.customerId',
          null
        );
      }
      obj.Q.phone_number = item.phone_number;

      obj.Q.what = 'DEL';
      obj = Q.clean_null_obj(obj);
      this.$store.dispatch('infopingstore/fetch_viewing_host', obj).then(() => {
        this.fetch_hosts();
      });

      this.user_name = '';
      this.phone_number = '';
    },
    add_viewing_host() {
      if (this.user_name == '' || this.phone_number == '') {
        this.alertDialogNoCancel(
          'Ofullständiga uppgifter',
          'Du måste skriva namn och mobilnummer för att lägga till en ny visningsvärd.',

          () => console.log('user ok')
        );
      } else {
        console.log('add host');
        var obj = Q.vitec_viewing_host_obj();
        obj.Q.batch_key = get(
          this.$store.state.infopingstore,
          'selected_viewing.item.batch_key',
          null
        );

        if (this.manage_assistants) {
          obj.Q.customerId = this.selectedOffice.customerId;
        } else {
          obj.Q.customerId = get(
            this.$store.state.infopingstore,
            'selected_viewing.item.customerId',
            null
          );
        }
        obj.Q.phone_number = this.phone_number;

        obj.Q.user_name = this.user_name;
        if (obj.Q.batch_key) obj.Q.access = '1';

        obj = Q.clean_null_obj(obj);
        this.$store
          .dispatch('infopingstore/fetch_viewing_host', obj)
          .then(() => {
            this.fetch_hosts();
          });

        this.user_name = '';
        this.phone_number = '';
      }
    },
    access_change(item) {
      //console.log(item);

      var obj = Q.vitec_viewing_host_obj();
      obj.Q.batch_key = get(
        this.$store.state.infopingstore,
        'selected_viewing.item.batch_key',
        null
      );

      obj.Q.customerId = get(item, 'customerId', null);
      obj.Q.phone_number = get(item, 'phone_number', null);

      obj.Q.user_name = get(item, 'user_name', null);
      if (item.access == true) obj.Q.access = '1';
      else obj.Q.access = '0';

      obj = Q.clean_null_obj(obj);
      //console.log(JSON.stringify(obj));
      this.$store.dispatch('infopingstore/fetch_viewing_host', obj);
    },

    close() {
      this.user_name = '';
      this.phone_number = '';
      this.$emit('closeMe');
    },

    alertDialog(headerText, bodyText, okFunc, cancelFunc) {
      this.$store.dispatch('infopingstore/showAlert', {
        show: true,
        headerText: headerText,
        bodyText: bodyText,
        showCancelButton: true,
        confirmAction: () => {
          okFunc();
        },
        cancelAction: () => {
          cancelFunc();
        },
      });
    },

    alertDialogNoCancel(headerText, bodyText, okFunc) {
      this.$store.dispatch('infopingstore/showAlert', {
        show: true,
        headerText: headerText,
        bodyText: bodyText,
        showCancelButton: false,
        confirmAction: () => {
          okFunc();
        },
      });
    },
  },
};
</script>

<style scoped></style>
