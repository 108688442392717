//----------------------------------------------
// SKARPA
//----------------------------------------------

export const URL_IP_FUNCTION = 'https://infoping.net/REST2/ask/Q/json/?function=';
export const URL_IP ='https://infoping.net/REST2/ask/Q/';


export const QUERY_URL_LITE ='https://infoping.net/REST2/ask/Q/';

//----------------------------------------------
// LOCAL HOST
//----------------------------------------------

// export const URL_IP_FUNCTION = 'http://localhost:57303/ask/Q/json/?function=';
// export const URL_IP ='http://localhost:57303/ask/Q/';


// export const QUERY_URL_LITE ='http://localhost:57303/ask/Q/';

//----------------------------------------------
// DEV
//----------------------------------------------

//export const URL_IP_FUNCTION = 'https://dev.infoping.se/REST2/ask/Q/json/?function=';
//export const URL_IP ='https://dev.infoping.se/REST2/ask/Q/';

//----------------------------------------------
// ALLA
//----------------------------------------------
export const COOKIE_NAME_SESSION = 'infopingSession'; 


