<template>
  <div
    :style="$vuetify.breakpoint.smAndUp ? null : 'margin-right: 25px'"
    :class="{ desktopView: $vuetify.breakpoint.mdAndUp }"
  >
    <br />

    <v-container class="borderContainer">
      <label>VITEC DATA</label>
      <br />
      <br />
      <div style="border-style:solid">
        <input
          style="color: #000000"
          type="file"
          ref="doc"
          @change="readFile()"
        />
      </div>

      <br />
      <v-btn @click="showTable = true" style="width: 350px; margin-bottom: 7px;"
        >klicka för att visa listan</v-btn
      >
      <br />
      <v-btn @click="download()" style="width: 350px"
        >klicka för att ladda ner listan</v-btn
      >
    </v-container>

    <v-card v-if="showTable">
      <v-card-title>
        Kontor
        <v-spacer></v-spacer>
      </v-card-title>
      <v-data-table :headers="headers" :items="offices"></v-data-table>
    </v-card>
  </div>
</template>

<script>
import get from 'lodash/get';
import componentsMixin from '@/components/componentsMixin.js';
//import Q from '@/questions.js';
//import { mapGetters } from 'vuex';
//import rest2Api from '@/api/rest2Api.js';

export default {
  name: 'Vitec',
  mixins: [componentsMixin],
  data() {
    return {
      session: get(this.$store.state.infopingstore, 'session', null),
      org_key: '',
      customerId: '',
      file: '',
      content: '',
      showTable: false,
      headers: [
        { text: 'Namn', value: 'userName' },
        { text: 'Titel', value: 'title' },
        { text: 'Kontor', value: 'office', sortable: false },
      ],

      filtered_brokers: [],
      brokers: [],
      office: [],
      result: [],
    };
  },
  watch: {},

  computed: {
    offices() {
      //  var arr = new Array();
      var arr = get(this.$store.state.infopingstore, 'vitec_data', []);

      if (arr.length > 0) {
        arr.forEach((element) => {
          if (element && element.title && element.title.includes('mäklare')) {
            if (
              element &&
              // element.archived == false &&
              // element.deleted == false &&
              // element.public == true
              //hatem har sagt att vi ska gå på allowLogOn
              element.allowLogOn == true
            ) {
              if (element && element.customerIdsWithSortOrder) {
                element.customerIdsWithSortOrder.forEach((kontor) => {
                  if (kontor && kontor.mainBusiness == true) {
                    console.log('kontor', kontor.customerId);
                    element.office = kontor.customerId;
                    if (!this.filtered_brokers.includes(element))
                      this.filtered_brokers.push(element);
                  }
                });
              }
            }
          }
        });
      }
      //console.log('arrnn522', this.filtered_brokers);ff
      return this.filtered_brokers;
    },
  },

  beforeMount() {},
  mounted() {},
  created() {},

  methods: {
    download() {
      // credit: https://www.bitdegree.org/learn/javascript-download
      //df g
      // lista med alla mäklare och kontorsid mm
      // let text = '';
      // this.filtered_brokers.forEach((element) => {
      //   //  console.l,og('tjipp', element);
      //   text = text + '\n' + element.userName + ',' + element.office;
      // });

      //array med alla mäklare och kontorsid
      //  let list = [];
      this.filtered_brokers.forEach((element) => {
        var line = {
          name: element.userName,
          id: element.office,
        };
        if (!this.brokers.includes(line)) this.brokers.push(line);
      });

      // lista med alla unika kontor
      //   let newArr = [];
      this.filtered_brokers.forEach((element) => {
        if (!this.office.includes(element.office))
          this.office.push(element.office);
      });

      //lista med alla mäklare som hör till ett visst kontor
      let newArr2 = [];
      var line = '';
      this.office.forEach((office) => {
        line = office + ' ';
        this.brokers.forEach((broker) => {
          if (broker.id == office) line = line + broker.name + ' ';
        });
        newArr2.push(line);
        //newArr2.push('\n');
      });

      let text = '';
      newArr2.forEach((element2) => {
        text = text + element2 + '\n';
      });

      let filename = 'cats.txt';
      let element = document.createElement('a');
      element.setAttribute(
        'href',
        'data:application/json;charset=utf-8,' + encodeURIComponent(text)
      );
      element.setAttribute('download', filename);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();
      document.body.removeChild(element);
    },

    getData() {
      //thi s.$store.dispatch('infopingstore/save_vitec', this.content);d
      //  console.log('filens content', this.content);
      //  this.$store.dispastch('infopingstore/fetch_vitec', this.customerId);
    },

    readFile() {
      this.file = this.$refs.doc.files[0];
      console.log('filen', this.file);
      const reader = new FileReader();
      // if (this.file.name.includes('.txt')) {
      reader.onload = (res) => {
        // console.log('res', res.tagret);
        //   console.log('res', res.target.result);
        this.content = res.target.result;

        this.$store.dispatch(
          'infopingstore/save_vitec',
          JSON.parse(this.content)
        );
      };
      //   reader.onerror = (err) => console.log(err);
      //   reader.readAsText(this.file);
      // } else {
      //   this.content = 'check the console for file output';
      //   reader.onload = (res) => {
      //     console.log(res.target.result);
      //   };
      //   reader.onerror = (err) => console.log(err);
      //   reader.readAsText(this.file);
      // }
      reader.readAsText(this.file);

      // } else
    },
  },
};
</script>
<style scoped></style>
