// import get from 'lodash/get';
// import moment from 'moment';
import rest2Api from "@/api/rest2Api.js";
import Q from "@/questions.js";
import VueScreenSize from 'vue-screen-size'
import get from "lodash/get";

//import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      
    };
  },
  mixins: [VueScreenSize.VueScreenSizeMixin],
  computed: {
 // ...mapGetters({ useCustomCSS: 'infopingstore/use_custom_css' }),//darkMode: 'infopingstore/get_dark_mode', darkLogo: 'infopingstore/get_dark_logo', 

    style_col_input_left(){
        var ar= []; 
        var result='';
        
      var w= this.$vssWidth;
      // var h= this.$vssHeight;
        if(w<4000)
        {
          ar.push('margin-top:0px');
          ar.push('margin-left:0px');
          ar.push('margin-bottom:0px');
          ar.push('margin-right:2px');
        } 
         
        result=ar.join();
        result= result.replace("\"", "")+";";
        result=result.replace(",", "; " )
        //console.log('style_col_input',result,w)
        return result;
    },
    style_col_input_right(){
      var ar= []; 
      var result='';
      var w= this.$vssWidth;
      //ar h=this.$vuetify.breakpoint.height;
      if(w<4000)
      {
        ar.push('margin-top:0px');
        ar.push('margin-left:2px');
        ar.push('margin-bottom:0px');
        ar.push('margin-right:0px');
      } 
      result=ar.join();
      result= result.replace("\"", "")+";";
      result=result.replace(",", "; " )
      //console.log('style_col_input',result,w)
      return result;
    },
    get_class_header() {
      return (
        "row-viewing-header pd-zero font-header-" +
        this.$vuetify.breakpoint.name
      );
    },
    get_class_start() {
      return (
        "row-viewing-start pd-zero font-start-" + this.$vuetify.breakpoint.name
      );
    },
      get_class_input() {
      return (
        " font-start-" + this.$vuetify.breakpoint.name
      );
    },
    get_class_vcard() {
      return "v-card-1";
    },
    
  
    get_width() {
      
      var w= this.$vssWidth;
  
      return w.toString();
      
       //return "200";
    },
    get_height() {
      
      var h= this.$vssHeight;
      //console.log('get_height',h)
      return h.toString();
      
       //return "200";
    },
    style_input() {
      
      var w= this.$vssWidth;
      var result='font-size:'
      var s='9px'
      if(w>1000)  
        s='14px'    
         
      else if(w>700) 
        s='16px'
      
      result=result+s+"; font-weight:bold"
     

      //console.log('style_input',result,w)
      return result;
      // return "200";
    },
    row_height() {
      var w= this.$vssWidth;
      var s='9'
      if(w>1000)
        s='20'
        
      else if(w>700)
        s='16'
      

      //console.log('row_height',s,w)
      return s;
      // return "200";
    },
    
  // min-height="1024";
  //   max-width="200";

    not_ok() {
      let answer = true;
      if (
        this.has_mobile == true &&
        this.registerItem.c[this.ix_attended] == true
      )
        answer = false;

      //('not_ok',this.has_mobile,this.registerItem.c[this.ix_attended],answer)
      return answer;
    },

    
    future_viewings() {
      return get(this.$store.state.infopingstore, 'list_viewings', []);
    },
    all_viewings() {
      return get(this.$store.state.infopingstore, 'list_all_viewings', []);
    },

  },
  methods: {
    is_valid_phone_number(phone_number){
        let answer=false;
        
        //console.log('is_valid_phone_number',phone_number,answer)
        if(phone_number!= null && phone_number.length != null & phone_number.length>5)
        {
          phone_number=phone_number.trim();
          let cnt=0;
          for(var i=0;i<phone_number.length;i++)
          {
            
              if(!isNaN(parseInt(phone_number.charAt(i))))
              {
                //console.log(phone_number.charAt(i))
                cnt=cnt+1;
              }
                
            }
            if(cnt>5)
              answer=true;
              
        //console.log('is_valid_phone_number',phone_number,cnt)
          }
          
       //console.log('is_valid_phone_number',phone_number,answer)
        return answer;
    },
    

    not_StartViewing(){
      if(this.$route.name=='StartViewing')
      return false;
      else return true;
    },
    screen_size() {
      var result = "";
      var w= this.$vssWidth;
      var h= this.$vssHeight;
      result=w.toString()+' x ' + h.toString();
      //console.log('screen_size',this.$vuetify.breakpoint.width,this.$vuetify.breakpoint.height)
      //console.log('screen_size',w,h)
      return result;
    },
    style_col_input(pos){
      var ar= []; 
      var w= this.$vssWidth;
      // var h= this.$vssHeight;
      var px=0;
      //ar h=this.$vuetify.breakpoint.height;
      // POS
      // left
      // right
      // middle
      // center

      if(w<350)
        px=2;
      else if(w<400)
        px=3;
      else if(w<450)
      px=4;
      else if(w<600)
      px=6;
      else if(w<800)
      px=10;
      else if(w<1200)
      px=16;
      else
      px=20;

      
      ar.push('margin-top:0px');
      ar.push('margin-bottom:0px');

      if(pos=='left')
      {
        ar.push('margin-left:0px');
        ar.push('margin-right:'+px.toString()+'px');
      } 
      else if(pos=='right')
      {
        ar.push('margin-left:'+px.toString()+'px');
        ar.push('margin-right:0px');
      } 
      else if(pos=='middle')
      {
        ar.push('margin-left:'+px.toString()+'px');
        ar.push('margin-right:'+px.toString()+'px');
      } 
      else 
      {
        ar.push('margin-left:0px');
        ar.push('margin-right:0px');
      } 
     return this.ar2str(ar); 
    
},
    ar2str(ar){
      var result='';
      result=ar.join();
      result= result.replace("\"", "")+";";
      result=result.replace(",", "; " )
      //console.log('ar2str',result)
      return result;
    },
    viewing_start_end(batch_key,batch_name,what){

      if(batch_key!=null && batch_name!= null && what!=null)
      {
        var obj = Q.ip_viewing_start_end_ins();
        obj.Q.batch_key=batch_key.toString();
        obj.Q.batch_name=batch_name.toString();
        obj.Q.what=what.toString()
        obj = Q.clean_null_obj(obj);
        rest2Api.viewing_start_end_ins(obj)
      }
    },

    empty_if_null(str){
      if(!str || str===null || str==='')
        return ''
        else
        return str.toString().trim();
    },

get_empty_array(l){
  var arr= new Array();
  return arr.splice(0, l)
},



getLang(c,w) {
      
     
  try {

  
    var filtered = this.$store.state.infopingstore.lang_filter.items.filter((x) => x.controller==c && x.what==w );
   
     return filtered[0].text;
  }
  catch

  {

    return w;
  }  
  },

  async setDefaultViewing(batch_key) {

      var item = this.future_viewings.find(
        (c) => c.batch_key == batch_key
      );
   
      if (!item) {
       //hämta alla visningar
        var session = get(this.$store.state.infopingstore, 'session', '');
        var obj = Q.vitec_batch_list();
        obj.Q.session = session;
        obj.Q.searchStr = '';
        obj = Q.clean_null_obj(obj);
        await this.$store.dispatch(
          'infopingstore/fetch_vitec_batch_list_all',
          obj
        );

        item = this.all_viewings.find(
          (c) => c.batch_key == batch_key
        );
      }
      if (item) {
        this.viewing_start_end(item.batch_key, item.batch_name, 'start');
        this.$store.dispatch('infopingstore/fetch_selected_viewing', {
          item: item,
        });
        return 'found viewing';
      } 
      else return 'not found';
  },
//funkade inte med variabler för QR print
  get_logo_url() {
 
    var org_key = get(
      this.$store.state.infopingstore.selected_viewing,
      'item.org_key',
      null
    );

    switch (org_key) {
      case '2282':
        return 'EOPrintLogo';
      case '2283':
        return 'NotarPrintLogo';
      case '2417':
        return 'SMHPrintLogo';
      case '1330':
        return 'InfopingPrintLogo';
      case '2712':
        return 'TreEtagePrintLogo';
      case '2698':
        return 'AhlmPrintLogo';
      case '2783':
        return 'BoportenPrintLogo';
      case '2697':
        return 'HHPrintLogo';
      case '2769':
        return 'MCentrumPrintLogo';
      case '2285':
        return 'MRPrintLogo';
      case '2764':
        return 'RamaPrintLogo';
      case '2763':
        return 'OlandPrintLogo';
      default:
        return 'VitecPrintLogo';
    }
  },
  customLogo(){
    if( this.orgKey == 2763 || this.orgKey == 2764  || this.orgKey == 2285 || this.orgKey == 2769 || this.orgKey == 2697 || this.orgKey == 2783 || this.orgKey == 2698 || this.orgKey == 2712 )
     return false;
    return true;
     },

  alertDialogNoCancel(headerText, bodyText, okFunc) {
    this.$store.dispatch('infopingstore/showAlert', {
      show: true,
      headerText: headerText,
      bodyText: bodyText,
      showCancelButton: false,
      confirmAction: () => {
        okFunc();
      },
    });
  },

  },
};
