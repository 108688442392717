<template>
  <div
    :style="$vuetify.breakpoint.smAndUp ? null : 'margin-right: 25px'"
    :class="{ desktopView: $vuetify.breakpoint.mdAndUp }"
  >
    <!-- <v-divider />-->
    <br />
    <br />

    <!-- <v-card flat style="max-width: 300px; padding: 15px">-->
    <v-container class="borderContainer" style="max-width: 600px">
      <label>LOGGA IN SOM MÄKLARE</label>
      <br />
      <br />
      <v-select
        v-if="this.support == '1'"
        outlined
        dense
        class="overflowOverride"
        :items="customers"
        item-text="name"
        item-value="org_key"
        label="Välj kund:"
        v-model="selectedCustomer"
      ></v-select>
      <v-select
        outlined
        dense
        class="overflowOverride"
        :items="offices"
        item-text="name"
        item-value="customerId"
        return-object
        label="Välj kontor:"
        v-model="selectedOffice"
      ></v-select>

      <div>
        <v-text-field
          outlined
          dense
          v-model="search"
          append-icon="mdi-magnify"
          label="Sök"
          single-line
          hide-details
        ></v-text-field>
        <br />
        <v-card outlined>
          <!-- </v-card-title>-->

          <v-data-table :headers="headers" :items="brokers" :search="search">
            <template v-slot:item.actions="{ item }">
              <!-- item-->
              <v-icon small class="mr-2" @click="login(item)">
                login
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </v-container>
    <!--
    <v-dialog v-model="loading">
      <v-card>
        <v-container style="height: 200px; max-width: 200px;">
          <v-row class="fill-height" align-content="center" justify="center">
            <v-col class="text-subtitle-1 text-center" cols="12">
              Laddar mäklare
            </v-col>
            <v-col cols="6">
              <v-progress-linear
                color="primary"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>-->
  </div>
</template>

<script>
import get from 'lodash/get';
import componentsMixin from '@/components/componentsMixin.js';

//import { mapGetters } from 'vuex';
export default {
  name: 'BrokerLogin',
  mixins: [componentsMixin],
  data() {
    return {
      search: '',
      selectedOffice: '',
      selectedCustomer: '',
      offices: [],

      headers: [
        { text: 'Namn', value: 'name' },
        { text: 'Mobilnr', value: 'what' },
        { text: 'Logga in', value: 'actions', sortable: false },
      ],
      //loading: false,
      org_key: '',
      // obj: new Object(),
    };
  },

  beforeMount() {
    this.$store.dispatch('infopingstore/clear_brokers');
    this.is_support();
  },

  mounted() {
    //om inte fler kunder och bara ett kontor välj det
    //   if (this.offices && this.offices.length == '1')
    //     this.selectedOffice = this.offices[0];
  },
  watch: {
    selectedOffice() {
      // this.loading = true;

      this.$store.dispatch('infopingstore/clear_brokers');

      const obj = new Object();

      obj.customerId = this.selectedOffice.customerId;
      if (!this.org_key) this.org_key = this.selectedCustomer;

      obj.name = 'share_brokers';
      obj.org_key = this.org_key;

      this.$store.dispatch('infopingstore/fetch_brokers', obj);
    },

    selectedCustomer() {
      this.$store.dispatch('infopingstore/clear_brokers');
      this.org_key = this.selectedCustomer;

      this.getOffices();
    },
  },
  computed: {
    //  ...mapGetters({ customers: 'infopingstore/customers' }),
    customers() {
      var orgs = this.$store.getters['infopingstore/access_orgs'](
        'share_brokers'
      );

      return orgs;
    },

    brokers() {
      var arr = new Array();
      var broker_login_brokers = get(
        this.$store.state.infopingstore,
        'brokers',
        []
      );

      if (broker_login_brokers.length > 0) {
        broker_login_brokers.forEach((element) => {
          if (
            element.customers[0].items &&
            element.customers[0].items.length > 0
          ) {
            element.customers[0].items.forEach((broker) => {
              var line = {
                name: broker.name,
                what: broker.what,
                info: broker.info,
              };
              if (!arr.includes(line)) arr.push(line);
            });
          }
        });
        //sortera array med objekt
        arr.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      }

      if (arr && arr.length == 0 && this.selectedOffice != '') {
        var line = {
          what: 'Inga mäklare',
        };
        arr.push(line);
      }

      return arr;
    },
  },
  methods: {
    getOffices() {
      var comp = this.$store.getters['infopingstore/broker_offices'](
        'share_brokers',
        this.org_key
      );

      this.offices = comp;

      if (this.offices && this.offices.length == '1')
        this.selectedOffice = this.offices[0];
    },

    login(item) {
      var user = encodeURIComponent(
        this.selectedOffice.name + ' - ' + item.name
      );

      window.open('/?s=' + item.info + '&cookies=false&user=' + user);
    },

    is_support() {
      //kolla om superagent och sätt org_key

      if (
        get(this.$store.state.infopingstore, 'user_type', null) == 'superagent'
      ) {
        this.support = '1';
      } else {
        this.support = '0';

        this.org_key = get(
          this.$store.state.infopingstore,
          'org_branding.org_key',
          null
        );
        this.getOffices();
      }
    },
  },
};
</script>
<style scoped>
/* anpassa höjden så att hela texten syns i v-select funkar inte globalt */
.overflowOverride ::v-deep .v-select__selection {
  overflow: visible !important;
}
</style>
